import { useState } from 'react';
import { handleFetchUsersAppointmentsByStatus } from 'redux/appointment';
import { UserStatuses } from 'types';
import {
  AppointmentSelectedTabTypes,
  AppointmentDetailsProps,
} from 'features/Appointments/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useOidcIdToken } from '@axa-fr/react-oidc';

export const useAppointmentTabs = () => {
  const { idToken } = useOidcIdToken();
  const dispatch = useAppDispatch();
  const { planBeneficiaries } = useAppSelector(
    (state) => state?.subscriptionStore,
  );
  const currentUserPlanBeneficiaryId = planBeneficiaries
    ? planBeneficiaries[0]?.id
    : null;
  const searchValue = useAppSelector((state) => state.sharedStore.searchTerm);

  const { loading: isLoading } = useAppSelector(
    (state) => state?.appointmentStore,
  );

  const [selectedTab, setSelectedTab] = useState<AppointmentSelectedTabTypes>(
    UserStatuses.Pending,
  );

  const [tabLoading, setTabLoading] = useState<Record<string, boolean>>({
    Pending: false,
    Scheduled: false,
    Declined: false,
    Completed: false,
  });

  const handleTabClick = async (tab: AppointmentSelectedTabTypes) => {
    setSelectedTab(tab);
    setTabLoading((prevState) => ({ ...prevState, [tab]: true }));

    switch (tab) {
      case UserStatuses.Pending:
        await dispatch(
          handleFetchUsersAppointmentsByStatus(
            currentUserPlanBeneficiaryId,
            1,
            idToken,
          ),
        );
        break;
      case UserStatuses.Scheduled:
        await dispatch(
          handleFetchUsersAppointmentsByStatus(
            currentUserPlanBeneficiaryId,
            2,
            idToken,
          ),
        );
        break;
      case UserStatuses.Declined:
        await dispatch(
          handleFetchUsersAppointmentsByStatus(
            currentUserPlanBeneficiaryId,
            3,
            idToken,
          ),
        );
        break;
      case UserStatuses.Completed:
        await dispatch(
          handleFetchUsersAppointmentsByStatus(
            currentUserPlanBeneficiaryId,
            4,
            idToken,
          ),
        );
        break;
    }
  };

  const filteredAppointmentsArray = (
    selectedTabAppointmentsArray: AppointmentDetailsProps[],
  ) => {
    if (!selectedTabAppointmentsArray) {
      return [];
    }

    return selectedTabAppointmentsArray
      ?.sort(
        (a: AppointmentDetailsProps, b: AppointmentDetailsProps) =>
          new Date(b.appointmentDate).getTime() -
          new Date(a.appointmentDate).getTime(),
      )
      ?.filter((appointment: AppointmentDetailsProps) => {
        const { appointmentFormat, professionalName, appointmentDate } =
          appointment;
        const fullName = `${appointment?.planBeneficiaryDto?.firstName} ${appointment?.planBeneficiaryDto?.lastName}`;
        return (
          appointment.appointmentStatus === selectedTab &&
          (fullName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            appointmentFormat
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            professionalName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            appointmentDate.toLowerCase().includes(searchValue.toLowerCase()))
        );
      });
  };

  const filteredAndSortedAppointmentsArray = (
    appointments: AppointmentDetailsProps[],
  ): AppointmentDetailsProps[] => {
    return appointments?.filter((appointment: AppointmentDetailsProps) => {
      const today = new Date();
      const appointmentDate = new Date(appointment.appointmentDate);

      if (
        appointment.appointmentStatus === UserStatuses.Completed ||
        appointment.appointmentStatus === UserStatuses.Declined
      ) {
        return true;
      }

      if (
        appointment.appointmentStatus === UserStatuses.Scheduled ||
        appointment.appointmentStatus === UserStatuses.Pending
      ) {
        return appointmentDate >= today;
      }

      return false;
    });
  };

  return {
    selectedTab,
    handleTabClick,
    isLoading,
    tabLoading,
    searchValue,
    filteredAppointmentsArray,
    filteredAndSortedAppointmentsArray,
  };
};
