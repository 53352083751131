import { Global } from '@mantine/core';

export const GlobalStyles = () => (
  <Global
    styles={(theme) => ({
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
      body: {
        ...theme.fn.fontStyles(),
        color: theme.colors.brand[9],
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
      form: {
        width: '100%',
      },
    })}
  />
);
