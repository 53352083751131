import ActionTypes from 'redux/action-types';

const initialState = {
  searchTerm: '',
  selectedTab: 'All',
  selectedStatusTab: 'Pending',
  calendar: [],
  sortedCalendar: [],
};

export const sharedStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH:
      return {
        ...state,
        searchTerm: action.payload,
      };

    case ActionTypes.SORT_CALENDAR_BY_DATE:
      return {
        ...state,
        sortedCalendar: action.payload,
      };

    default:
      return state;
  }
};
