import { useState, FormEvent } from 'react';
import { IMessage } from 'features/AISupport/types';
import { createStyles, Textarea, Button, useMantineTheme } from '@mantine/core';
import { BiSend } from 'react-icons/bi';

interface ChatFormProps {
  onSendMessage: (message: IMessage) => void;
  messages: IMessage[];
}

const useStyles = createStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.lg,
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderRadius: theme.radius.lg,
    [theme.fn.smallerThan('lg')]: {
      maxWidth: '100%',
    },
  },
  textarea: {
    resize: 'none',
    margin: `0 ${theme.spacing.md}`,
    padding: theme.spacing.xs,
    width: '90%',
    fontSize: theme.fontSizes.sm,
    border:
      theme.colorScheme === 'dark' ? '' : `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    background:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.white,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
}));

const ChatForm = ({ onSendMessage, messages }: ChatFormProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [newMessage, setNewMessage] = useState('');
  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();

    if (!newMessage) {
      return;
    }

    const timestamp = new Date().toISOString();
    const newMessageObj: IMessage = {
      id: messages.length + 1,
      content: newMessage,
      timestamp,
      sender: 'user',
      status: 'unread',
    };
    onSendMessage(newMessageObj);
    setNewMessage('');
  };

  return (
    <form className={classes.form} onSubmit={handleSendMessage}>
      <Textarea
        id="chat"
        rows={2}
        className={classes.textarea}
        placeholder="Type a message"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
      />
      {theme.fn.smallerThan('md') ? (
        <Button type="submit" variant="filled" radius="sm" p="sm" mr="sm">
          <BiSend />
        </Button>
      ) : (
        <Button
          type="submit"
          variant="filled"
          leftIcon={<BiSend />}
          radius="md"
        >
          <span>Send</span>
        </Button>
      )}
    </form>
  );
};

export default ChatForm;
