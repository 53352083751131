import ActionTypes from 'redux/action-types';
import API from 'redux/api-endpoints';
import { APP_ROUTES_LIST } from 'types';
import {
  handleRequestErrors,
  showErrorNotification,
  showSuccessNotification,
} from 'redux/utils';
import { handleMatchRouteWithDynamicKey } from 'utils';
import { handleGetJson, handlePutJson } from 'utils/http';
import { handleUserLogOut } from 'redux/auth';

export const handleFetchCurrentUserDetails = (oidcFetch, logout) => {
  return async (dispatch) => {
    const currentPath = window.location.pathname;
    const shouldFetchData = Object.values(APP_ROUTES_LIST).some((route) => {
      let routeToUse = '';
      if (route === APP_ROUTES_LIST.PRESCRIPTIONS_WITH_ID) {
        routeToUse = route + '*';
      } else {
        routeToUse = route;
      }
      return handleMatchRouteWithDynamicKey(routeToUse, currentPath);
    });
    if (shouldFetchData) {
      dispatch({
        type: ActionTypes.GET_CURRENT_USER_DETAILS_START,
      });
      try {
        const apiEndpoint = API.currentUserBiodata();
        const response = await oidcFetch(apiEndpoint);
        if (!response.ok) {
          if (response.status === 401) {
            dispatch(handleUserLogOut());
          }
          return;
        }
        const data = await response.json();
        return dispatch({
          type: ActionTypes.GET_CURRENT_USER_DETAILS_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const errorMessage = handleRequestErrors(error);
        // from redux utils
        showErrorNotification(
          'get-current-user-data-error',
          errorMessage,
          'Get Current User Data Error',
        );
        return dispatch({
          type: ActionTypes.GET_CURRENT_USER_DETAILS_ERROR,
          payload: errorMessage,
        });
      }
    }
  };
};

export const handleUpdatecurrentUser = (data, userId, idToken) => {
  return handlePutJson(
    API.updatecurrentUser(userId),
    data,
    idToken,
    ActionTypes.UPDATE_CURRENT_USER_DETAILS_START,
    ActionTypes.UPDATE_CURRENT_USER_DETAILS_SUCCESS,
    ActionTypes.UPDATE_CURRENT_USER_DETAILS_ERROR,
    'Updated User details successfully',
  );
};
export const uploadAvatar = (formData, userId, idToken) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.UPLOAD_AVATAR_START });

    try {
      const fileObject = new FormData();
      fileObject.append('file', formData);

      const response = await fetch(API.uploadAvatar(userId), {
        method: 'POST',
        body: fileObject,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (!response.ok) {
        return showErrorNotification(
          'upload-avatar-response-error',
          `Failed to update profile picture. Status: ${response.status}`,
          'Updating profile failed',
        );
      }

      await showSuccessNotification(
        'upload-avatar-success',
        'Uploaded profile picture successfully',
      );
      return dispatch({
        type: ActionTypes.UPLOAD_AVATAR_SUCCESS,
        payload: null,
      });
    } catch (error) {
      const errorMessage = handleRequestErrors(error);
      showErrorNotification(
        'upload-avatar-error',
        errorMessage,
        'Upload Profile Picture Error',
      );
      return dispatch({
        type: ActionTypes.UPLOAD_AVATAR_ERROR,
        payload: errorMessage,
      });
    }
  };
};

export const handleFetchGenderList = (idToken) => {
  return handleGetJson(
    idToken,
    API.getGenderList(),
    ActionTypes.FETCH_GENDER_LIST_START,
    ActionTypes.FETCH_GENDER_LIST_SUCCESS,
    ActionTypes.FETCH_GENDER_LIST_ERROR,
    '',
  );
};

export const handleFetchRelationshipList = (idToken) => {
  return handleGetJson(
    idToken,
    API.getRelationshipList(),
    ActionTypes.FETCH_USER_RELATIONSHIP_LIST_START,
    ActionTypes.FETCH_USER_RELATIONSHIP_LIST_SUCCESS,
    ActionTypes.FETCH_USER_RELATIONSHIP_LIST_ERROR,
    '',
  );
};

export const handleFetchAllProfessionalsDetailsFromBackend = (idToken) => {
  return handleGetJson(
    idToken,
    API.getAllProfessionalsDetailsFromBackend(),
    ActionTypes.FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_START,
    ActionTypes.FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_SUCCESS,
    ActionTypes.FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_ERROR,
  );
};
