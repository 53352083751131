import ActionTypes from 'redux/action-types';

const initialState: any = {
  success: false,
  error: false,
  submitting: false,
  // I have this additional boolean becuase the general sucess was also generating when any of the requests was successful not specifically waiting for the active user plan
  isActivePlanSuccess: false,
  activeUserPlan: {},
  planBeneficiaries: [],
  userPlanHistory: [],
  healthPlans: [],
  planDurations: [],
  // * important - The initialization is cos of the freemium we have
  selectedHealthPlanForSubscription: {
    description: 'Free Plan',
    extraPersonPrice: 0,
    id: 6,
    maxPerson: 1,
    name: 'Freemium',
    price: 0,
  },
  // * important - The initialization so that if a user doesn't choose the plan duration in the confirm-subscriptionn route, it sets the value of the tab to be this
  selectedPlanDurationForSubscription: {
    description: '1 Month',
    id: 1,
    name: 'Monthly',
  },
  selectedBeneficiaryForEditingSubscription: {},
  paymentReferenceData: {},
};

export const subscriptionReducer = (
  state = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case ActionTypes.FETCH_ACTIVE_USER_PLAN_WITH_USERID_START:
    case ActionTypes.FETCH_USER_PLAN_HISTORY_WITH_USERID_START:
    case ActionTypes.FETCH_BENEFICIARIES_WITH_USERPLAN_ID_START:
    case ActionTypes.FETCH_HEALTH_PLANS_AND_DURATIONS_START:
    case ActionTypes.GENERATE_PAYMENT_PLAN_REFERENCE_START:
    case ActionTypes.POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_START:
    case ActionTypes.UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START:
    case ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START:
    case ActionTypes.DELETE_USER_PLAN_WITH_USERPLAN_ID_START:
      return {
        ...state,
        success: false,
        error: false,
        submitting: true,
        isActivePlanSuccess: false,
      };
    case ActionTypes.POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_SUCCESS:
    case ActionTypes.UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS:
    case ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS:
    case ActionTypes.DELETE_USER_PLAN_WITH_USERPLAN_ID_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
      };
    case ActionTypes.FETCH_ACTIVE_USER_PLAN_WITH_USERID_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        isActivePlanSuccess: true,
        activeUserPlan:
          action.payload.userPlans.length > 0
            ? action.payload.userPlans[0]
            : state.activeUserPlan,
      };
    case ActionTypes.FETCH_HEALTH_PLANS_AND_DURATIONS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        healthPlans: action.payload.healthPlans,
        planDurations: action.payload.planDurations,
      };
    case ActionTypes.FETCH_BENEFICIARIES_WITH_USERPLAN_ID_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        planBeneficiaries: action.payload.planBeneficiaries,
      };
    case ActionTypes.FETCH_USER_PLAN_HISTORY_WITH_USERID_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        userPlanHistory: action.payload.userPlans,
      };
    case ActionTypes.FETCH_ACTIVE_USER_PLAN_WITH_USERID_ERROR:
    case ActionTypes.FETCH_USER_PLAN_HISTORY_WITH_USERID_ERROR:
    case ActionTypes.FETCH_BENEFICIARIES_WITH_USERPLAN_ID_ERROR:
    case ActionTypes.FETCH_HEALTH_PLANS_AND_DURATIONS_ERROR:
    case ActionTypes.GENERATE_PAYMENT_PLAN_REFERENCE_ERROR:
    case ActionTypes.POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_ERROR:
    case ActionTypes.UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR:
    case ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR:
    case ActionTypes.DELETE_USER_PLAN_WITH_USERPLAN_ID_ERROR:
      return {
        ...state,
        success: false,
        isActivePlanSuccess: false,
        error: true,
        submitting: false,
      };
    case ActionTypes.GENERATE_PAYMENT_PLAN_REFERENCE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        paymentReferenceData: action.payload,
      };
    case ActionTypes.SELECTED_USER_HEALTHPLAN_FOR_SUBSCRIPTION:
      return {
        ...state,
        selectedHealthPlanForSubscription: action.payload,
      };

    case ActionTypes.SELECTED_PLAN_DURATION_FOR_SUBSCRIPTION:
      return {
        ...state,
        selectedPlanDurationForSubscription: {
          ...state.selectedPlanDurationForSubscription,
          ...action.payload,
        },
      };
    case ActionTypes.SELECTED_BENEFICIARY_FOR_EDITING_SUBSCRIPTION:
      return {
        ...state,
        selectedBeneficiaryForEditingSubscription: action.payload,
      };
    default:
      return state;
  }
};
