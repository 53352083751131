import { Stack, StackProps, createStyles } from '@mantine/core';
import { SettingsCard } from 'features/Settings/components/SettingsCard';
import { BsSun, BsChevronRight, BsQuestionCircle } from 'react-icons/bs';
import { GoMail } from 'react-icons/go';
import { MdSupportAgent } from 'react-icons/md';
import { Switch } from 'components/Forms/Switch';
import { DarkModeToggle } from 'themes';
import { useTranslation } from 'react-i18next';

export interface SettingsUIContainer extends StackProps {}
export const useSwitchToggleStyles = createStyles(() => ({
  switch: {
    '& *': {
      cursor: 'pointer',
    },
  },
}));

export const SettingsUIContainer = ({ ...otherProps }: SettingsUIContainer) => {
  const { classes } = useSwitchToggleStyles();
  const { t } = useTranslation();

  const settingsProps = [
    {
      header: t('settings.App'),
      value: [
        {
          title: t('settings.Theme'),
          leftIcon: <BsSun />,
          rightIcon: <DarkModeToggle />,
          link: '',
        },
      ],
    },
    {
      header: t('settings.Notification'),
      value: [
        {
          title: t('settings.EmailNotification'),
          leftIcon: <GoMail />,
          rightIcon: (
            <Switch
              onLabel={'ON'}
              offLabel={'OFF'}
              className={classes.switch}
              size="lg"
              color="dark"
            />
          ),
          link: '',
        },
      ],
    },
    {
      header: t('settings.Help'),
      value: [
        {
          title: t('settings.FAQs'),
          leftIcon: <BsQuestionCircle />,
          rightIcon: <BsChevronRight />,
          link: 'https://homnics.com/faq.html',
        },
        {
          title: t('settings.ContactSupport'),
          leftIcon: <MdSupportAgent />,
          rightIcon: <BsChevronRight />,
          link: 'mailto:support@homnics.com',
        },
      ],
    },
  ];
  return (
    <Stack {...otherProps}>
      <SettingsCard settingsProps={settingsProps} />
    </Stack>
  );
};
