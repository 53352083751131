import React from 'react';
import { useField } from 'formik';
import { DatePicker, DatePickerProps } from '@mantine/dates';

interface FormikDatePickerProps extends DatePickerProps {
  name: string;
}

export const DateInputField = (props: FormikDatePickerProps) => {
  const [field, meta, helpers] = useField(props.name);

  const { onBlur, onChange, placeholder, ...otherProps } = props;

  const handleSelectChange = (value: Date) => {
    helpers.setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleSelectBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    helpers.setTouched(true);
    onBlur && onBlur(event);
  };

  // Convert field.value to a Date type
  const dateValue = field.value ? new Date(field.value) : null;

  return (
    <DatePicker
      {...otherProps}
      value={dateValue}
      onChange={handleSelectChange}
      onBlur={handleSelectBlur}
      error={meta.touched && meta.error}
      placeholder={placeholder}
    />
  );
};
