import { useState, useMemo } from 'react';

export const usePaginationHook = (data: any[], pageSize: number) => {
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return data?.slice(start, end);
  }, [data, currentPage, pageSize]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return { currentPage, paginatedData, handlePageChange };
};
