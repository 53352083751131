import { DayOfWeekEnum, MonthEnum } from 'types';
import { AppointmentDetailsProps } from 'features/Appointments/types';

export function addSpacingBeforeCapital(text: string) {
  return text.replace(/([A-Z])/g, ' $1').trim();
}

export function handleGetPluralizedWord(
  count: number,
  singular: string,
  plural: string,
) {
  return count <= 1 ? singular : plural;
}

export const handleCalculateAge = (birthdate: string) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age.toString();
};

export const handleFormatDate = (date: string) => {
  const currentDate = new Date();
  const givenDate = new Date(date);
  const diffInDays = Math.floor(
    (Date.UTC(
      givenDate.getFullYear(),
      givenDate.getMonth(),
      givenDate.getDate(),
    ) -
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
      )) /
      (1000 * 60 * 60 * 24),
  );

  let formattedDate = '';
  if (diffInDays === 0) {
    formattedDate = 'Today';
  } else if (diffInDays === 1) {
    formattedDate = 'Tomorrow';
  } else {
    formattedDate = givenDate.toLocaleDateString('en-GB');
  }

  const formattedTime = givenDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${formattedDate}, ${formattedTime}`;
};

export const handleFormatDateWithoutTime = (date: string) => {
  const givenDate = new Date(date);
  const formattedDate = givenDate.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return formattedDate;
};

export const handleFormatDateForBackend = (date: Date): string => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const handleFormatTimeForBackend = (inputDate: Date): string => {
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const handleSubtractTimeFromStringBefore = (
  value: string,
  inputDate: Date,
): string | null => {
  const match = value.match(/^(\d+)\s*(hour|minute|hours|minutes)\s*before$/i);

  if (!match) {
    // Invalid input format
    return null;
  }

  const amount = parseInt(match[1], 10);
  const unit = match[2].toLowerCase();

  // Create a copy of the input date
  const subtractedDate = new Date(inputDate);

  if (unit === 'hour' || unit === 'hours') {
    subtractedDate.setHours(inputDate.getHours() - amount);
  } else if (unit === 'minute' || unit === 'minutes') {
    subtractedDate.setMinutes(inputDate.getMinutes() - amount);
  }

  // Format the subtracted time using handleFormatTimeForBackend
  const formattedTime = handleFormatTimeForBackend(subtractedDate);

  return formattedTime;
};

export function handleGetCurrentDate(dateParam?: Date) {
  const daysOfWeek: string[] = Object.values(DayOfWeekEnum);
  const monthsOfYear: string[] = Object.values(MonthEnum);

  const now = dateParam ? new Date(dateParam) : new Date();
  const dayOfWeek = daysOfWeek[now.getDay()];
  const date = now.getDate();
  const month = monthsOfYear[now.getMonth()];
  const year = now.getFullYear();

  return `${dayOfWeek}, ${date} ${month}, ${year}`;
}

export function handleGetCurrentTime(dateParam?: Date) {
  const now = dateParam ? new Date(dateParam) : new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const handleEscapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const handleMatchRouteWithDynamicKey = (
  route: string,
  currentPath: string,
) => {
  const routeWithDynamicKey = route?.replace(/:\w+\*/, '');
  const dynamicKey = currentPath?.replace(routeWithDynamicKey, '');

  const routePattern = new RegExp(
    '^' + route.replace(/:\w+/, handleEscapeRegExp(dynamicKey)) + '$',
  );

  return routePattern.test(currentPath);
};

export const handleCapitalizeFirstChar = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return;
};

export function handleFormatNumberWithCommas(input: number | string): string {
  if (typeof input === 'number') {
    return input.toLocaleString();
  } else if (typeof input === 'string') {
    const parsedNumber = parseFloat(input);
    if (!isNaN(parsedNumber)) {
      return parsedNumber.toLocaleString();
    }
  }
  return 'Invalid input';
}

export const handleGetAppointmentsForToDay = (
  appointments: AppointmentDetailsProps[],
  day = new Date(),
) => {
  const formattedDay = day.toLocaleDateString();
  return appointments?.filter((appointment: AppointmentDetailsProps) => {
    const appointmentDate = new Date(
      appointment.appointmentDate,
    ).toLocaleDateString();
    return appointmentDate === formattedDay;
  });
};

export function isToday(dateString: Date | string) {
  const today = new Date();
  const parsedDate = new Date(dateString);
  return (
    today.getFullYear() === parsedDate.getFullYear() &&
    today.getMonth() === parsedDate.getMonth() &&
    today.getDate() === parsedDate.getDate()
  );
}

export function isYesterday(dateString: Date | string) {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const parsedDate = new Date(dateString);
  return (
    yesterday.getFullYear() === parsedDate.getFullYear() &&
    yesterday.getMonth() === parsedDate.getMonth() &&
    yesterday.getDate() === parsedDate.getDate()
  );
}
