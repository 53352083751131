import { FieldProps } from 'formik';
import { Field, FieldBaseProps } from './FieldBase';
import { TextareaProps, Textarea } from '@mantine/core';
import { getFieldError } from './_utils';

interface TextareaFieldProps
  extends Omit<FieldBaseProps, 'children' | 'component' | 'as' | 'type'>,
    Omit<TextareaProps, 'value' | 'type' | 'name'> {}

export interface FieldToTextareaProps
  extends Omit<FieldProps, 'type'>,
    Omit<TextareaProps, 'form'> {}

function fieldToTextarea({
  field: { onBlur: onFieldBlur, ...field },
  form: { touched, errors },
  onBlur,
  ...props
}: FieldToTextareaProps): TextareaProps {
  return {
    onBlur:
      onBlur ??
      function (e) {
        onFieldBlur(e ?? field.name);
      },
    ...field,
    ...props,
    error: getFieldError(field.name, errors, touched),
  };
}

function FieldToTextarea({ ...props }: FieldToTextareaProps): JSX.Element {
  return <Textarea {...fieldToTextarea(props)} />;
}

export function TextareaInputField({
  ...props
}: TextareaFieldProps): JSX.Element {
  return <Field {...props} component={FieldToTextarea} />;
}
