import { Stack, Table, ScrollArea, createStyles } from '@mantine/core';
import {
  MedicationPrescription,
  IPrescriptionData,
} from 'features/Prescriptions/types';
import { usePrescriptionHook } from 'features/Prescriptions/hooks/usePrescriptionHook';
import { useAppSelector } from 'redux/hooks';

export const usePatientPrescriptionTableStyles = createStyles((theme) => ({
  root: {
    overflowX: 'auto',
  },
  tableHeader: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[4],
    color: theme.black,
    fontSize: 40,
  },
  tableBody: {
    fontWeight: 500,
  },
  action: {
    cursor: 'pointer',
  },
}));

interface PatientPrescriptionTableProps extends IPrescriptionData {}

export const PatientPrescriptionTable = ({
  ...prescriptions
}: PatientPrescriptionTableProps) => {
  const { classes } = usePatientPrescriptionTableStyles();
  const { id } = prescriptions;
  const { handleGetMedicationNameWithMedicationId } = usePrescriptionHook();

  const { patientPrescriptions } = useAppSelector(
    (state) => state?.prescriptionStore,
  );

  // Function to create a single row for a medication
  const createMedicationRow = (
    medication: MedicationPrescription,
    medicationName: string,
    index: number,
  ) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{medicationName}</td>
        <td>{medication.dosage}</td>
        <td>{medication.format}</td>
        <td>{medication.instruction}</td>
      </tr>
    );
  };

  const createTableRowsWithBackendData = () => {
    let rowIndex = 0; // keep count of the index for its order on the table
    return patientPrescriptions
      ?.filter((prescription: IPrescriptionData) => prescription.id === id)
      .map((prescription: IPrescriptionData) => {
        return prescription.medications.map(
          (medication: MedicationPrescription) => {
            const medicationId = medication.medicationId;
            const medicationName = handleGetMedicationNameWithMedicationId(
              medicationId as string,
            );
            const rowKey = rowIndex++; // Use the rowIndex as the key for each row
            return createMedicationRow(medication, medicationName, rowKey);
          },
        );
      })
      .flat();
  };

  return (
    <ScrollArea>
      <Stack className={classes.root}>
        <Table withBorder sx={{ minWidth: 800 }} verticalSpacing="md">
          <thead className={classes.tableHeader}>
            <tr>
              <th>s/n</th>
              <th>Medicine Name</th>
              <th>Dosage</th>
              <th>Format</th>
              <th>Instructions</th>
            </tr>
          </thead>
          <tbody className={classes.tableBody}>
            {createTableRowsWithBackendData()}
          </tbody>
        </Table>
      </Stack>
    </ScrollArea>
  );
};
