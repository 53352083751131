import {
  StackProps,
  GroupProps,
  TextProps,
  createStyles,
  useMantineTheme,
  Group,
  Title,
  Stack,
} from '@mantine/core';
import { IconBrandLogo, IconBrandLogoProps } from 'icons';

const useStyles = createStyles((theme) => ({
  root: {
    fontSize: theme.fontSizes.xl * 4,
  },
  brand: {
    fontSize: theme.fontSizes.xl * 2.2,
  },
}));

export interface BrandLogoProps {
  variant?: 'full' | 'icon' | 'text';
  orientation?: 'horizontal' | 'vertical';
  containerProps?: StackProps | GroupProps;
  textProps?: TextProps;
  iconProps?: IconBrandLogoProps;
}

const AppBrandLogo = ({
  textProps,
  iconProps,
  variant,
}: Partial<BrandLogoProps>) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  if (variant === 'icon')
    return (
      <IconBrandLogo
        {...iconProps}
        fill={theme.colors.secondary[9]}
        homeFill={theme.colors.secondary[4]}
      />
    );

  if (variant === 'text')
    return (
      <Title className={classes.brand} {...textProps}>
        Homnics
      </Title>
    );

  return (
    <>
      <IconBrandLogo
        {...iconProps}
        fill={theme.colors.secondary[9]}
        homeFill={theme.colors.secondary[4]}
      />

      <Title className={classes.brand} {...textProps}>
        Homnics
      </Title>
    </>
  );
};

export const BrandLogo = ({
  orientation = 'vertical',
  containerProps,
  ...otherProps
}: BrandLogoProps) => {
  const { classes, cx } = useStyles();

  const { className, ...otherContainerProps } = containerProps || {};

  const sharedProps: StackProps | GroupProps = {
    spacing: 'xs',
    ...otherContainerProps,
  };

  if (orientation === 'vertical')
    return (
      <Stack
        align={'center'}
        className={cx(classes.root, className)}
        {...sharedProps}
      >
        <AppBrandLogo {...otherProps} />
      </Stack>
    );

  return (
    <Group className={cx(classes.root, className)} {...sharedProps}>
      <AppBrandLogo {...otherProps} />
    </Group>
  );
};
