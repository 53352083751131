import { Group, Stack, Title, Chip } from '@mantine/core';

interface BookBadgeProps {
  title: string;
  values: string[];
  name: string;
  setInputValue: (inputValue: string[] | string) => void;
  inputValue: string[] | string;
  isMultiple: boolean;
}

export const BookBadge = ({
  title,
  values,
  name,
  inputValue,
  isMultiple = false,
  setInputValue,
}: BookBadgeProps) => {
  return (
    <Stack>
      <Title size={18}>{title}</Title>{' '}
      <Chip.Group
        multiple={isMultiple ? true : false}
        value={inputValue}
        onChange={setInputValue}
      >
        <Group mt="sm">
          {values.map((value) => {
            return (
              <Chip
                value={value}
                color="dark"
                variant="outline"
                key={value}
                name={name}
                size="md"
              >
                {value}
              </Chip>
            );
          })}
        </Group>
      </Chip.Group>
    </Stack>
  );
};
