import { Stack, StackProps } from '@mantine/core';
import { PaginationComponent, usePaginationHook } from 'components/Pagination';
import { AppointmentItem } from 'features/Appointments/components/AppointmentItem';
import { AppointmentDetailsProps } from 'features/Appointments/types';

interface AppointmentProps extends StackProps {
  data: AppointmentDetailsProps[];
}

export const Appointments = ({ data, ...otherProps }: AppointmentProps) => {
  const pageSize = 5;
  const { currentPage, paginatedData, handlePageChange } = usePaginationHook(
    data,
    pageSize,
  );

  return (
    <Stack spacing="xs" {...otherProps}>
      {paginatedData.map((appointments) => (
        <AppointmentItem
          key={appointments.id}
          symptoms={appointments?.symptoms}
          {...appointments}
        />
      ))}
      <PaginationComponent
        total={Math.ceil(data?.length / pageSize)}
        page={currentPage}
        onChange={handlePageChange}
      />
    </Stack>
  );
};
