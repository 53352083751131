import ActionTypes from 'redux/action-types';
import API from 'redux/api-endpoints';
import { handleGetJson } from 'utils/http';

export const handleFetchAllMedicationsFromBackend = (idToken) => {
  return handleGetJson(
    idToken,
    API.getAllMedications(),
    ActionTypes.FETCH_ALL_MEDICATIONS_START,
    ActionTypes.FETCH_ALL_MEDICATIONS_SUCCESS,
    ActionTypes.FETCH_ALL_MEDICATIONS_ERROR,
    '',
  );
};

export const handleFetchPatientPrescriptionsByBeneficiaryId = (
  beneficiaryId,
  idToken,
) => {
  async () => {
    if (!beneficiaryId) {
      return;
    }
  };

  return handleGetJson(
    idToken,
    API.getPatientPrescriptionsByBeneficiaryId(beneficiaryId),
    ActionTypes.FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_START,
    ActionTypes.FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_SUCCESS,
    ActionTypes.FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_ERROR,
    '',
  );
};
