import {
  Switch as MantineSwitch,
  SwitchProps as MantineSwitchProps,
} from '@mantine/core';

export interface SwitchProps extends MantineSwitchProps {
  customProp?: string;
}

export const Switch = ({ ...otherProps }: SwitchProps) => {
  return <MantineSwitch {...otherProps} />;
};
