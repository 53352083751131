import API from 'redux/api-endpoints';
import ActionTypes from 'redux/action-types';
import { handleGetJson, handlePutJson } from 'utils/http';

export const handleFetchNotificationsFromBackend = (userId, page, idToken) => {
  if (!userId) {
    return;
  }

  const updatedPageNumber = Math.max(1, page);

  return handleGetJson(
    idToken,
    API.getNotificationsFromBackend(userId, updatedPageNumber),
    ActionTypes.FETCH_NOTIFICATIONS_START,
    ActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
    ActionTypes.FETCH_NOTIFICATIONS_ERROR,
    '',
  );
};

export const handleUpdateNotificationsToReadToBackend = (data, idToken) => {
  return handlePutJson(
    API.updateNotificationsToRead(),
    data,
    idToken,
    ActionTypes.UPDATE_NOTIFICATIONS_TO_READ_START,
    ActionTypes.UPDATE_NOTIFICATIONS_TO_READ_SUCCESS,
    ActionTypes.UPDATE_NOTIFICATIONS_TO_READ_ERROR,
    '',
  );
};
