// sometimes, array returns as type of string. This function handles that case with regex
export const handleCleanAndFormatArrayProperty = (value: any) => {
  if (typeof value === 'string') {
    return value
      ?.replace(/\[|\]|"|/g, '') // Remove unwanted characters
      .slice(0, 30) // Limit the string to the first 30 characters
      .split(',') // Split the string by commas
      .map((day) => day.trim()) // Remove leading/trailing spaces from each day
      .join(', '); // Join the array with commas and spaces // Remove unwanted characters
  }
  if (Array.isArray(value)) {
    return value
      .map((item) => item.trim())
      .slice(0, 30)
      .join(', '); // Remove leading/trailing spaces, limit to 30 characters, and format array
  }
  return value; // For other types, return as is
};
