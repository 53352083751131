import { AnchorProps, Anchor } from '@mantine/core';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export interface LinkProps extends AnchorProps, Pick<RouterLinkProps, 'to'> {}

export const Link = ({ ...otherProps }: LinkProps) => (
  <Anchor component={RouterLink} {...otherProps} />
);
