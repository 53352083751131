import { createStyles, Box, Title, Stack, Group, Button } from '@mantine/core';
import { DateInputField } from 'components/Formik/DateInputField';
import { SelectInputField } from 'components/Formik/SelectInputField';
import { TextareaInputField } from 'components/Formik/TextareaInputField';
import { TimeInputField } from 'components/Formik/TimeInputField';
import { BookBadge } from 'features/BookAppointment/components/BookBadge';
import { useBookAppointmentValidationSchema } from 'features/BookAppointment/hooks/BookAppointmentFormSchema';
import { IBookAppointmentFormValues } from 'features/BookAppointment/types';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCalendar } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import {
  handleBookAppointmentFormData,
  handleSelectedSymptomsForAppointmentBooking,
} from 'redux/appointment';
import { useAppDispatch } from 'redux/hooks';
import { APP_ROUTES_LIST } from 'types';
import { showErrorNotification } from 'redux/utils';

export const useBookAppointmentStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    margin: 'auto',
    [theme.fn.smallerThan('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    [theme.fn.largerThan('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  textarea: {
    width: '100%',
  },
  textinput: {
    border: '1px solid',
    borderColor: theme.colors.tertiary[0],
    borderRadius: 8,
    background: 'none',
    '&:hover': {
      border: `1px solid ${theme.colors.brand[5]}`,
    },
  },
}));

export const BookAppointmentFormContainer = () => {
  const { t } = useTranslation();
  const { classes } = useBookAppointmentStyles();
  const validationSchema = useBookAppointmentValidationSchema();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [symptomsInputValues, setSymptomsInputValues] = useState<any>([]);

  const meetingFormat = [
    { value: 'Video', label: 'Video' },
    { value: 'Audio', label: 'Audio' },
  ];

  useEffect(() => {
    dispatch(handleSelectedSymptomsForAppointmentBooking(symptomsInputValues));
  }, [symptomsInputValues]);

  return (
    <Stack className={classes.root}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesBookAppointment}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (new Date(values.date) < new Date()) {
            showErrorNotification(
              'error-id',
              'Appointment date cannot be in the past',
            );
            return;
          }
          dispatch(handleBookAppointmentFormData(values));
          navigate(APP_ROUTES_LIST.PROFESSIONALSLIST);
        }}
      >
        <Form>
          <Stack sx={{ margin: 'auto' }} spacing={'xl'} justify="center">
            <Group>
              <DateInputField
                placeholder={t('booking.PickDate')}
                inputFormat="MM/DD/YYYY"
                labelFormat="MM/YYYY"
                name="date"
                icon={<BiCalendar size={16} />}
                minDate={new Date()}
              />
              <TimeInputField timePlaceholder="10" format="12" name="time" />
              <SelectInputField
                name="format"
                options={meetingFormat}
                placeholder="Format"
                data={meetingFormat}
                w={100}
              />
            </Group>
            <BookBadge
              title={t('booking.HowAreYouFeelingOptional')}
              values={['Headache', 'Fatigue', 'Fever', 'Cough']}
              name="symptoms"
              inputValue={symptomsInputValues}
              setInputValue={setSymptomsInputValues}
              isMultiple={true}
            />
            <Title size={18}>{t('booking.AdditionalInfoOptional')}</Title>
            <Box className={classes.textarea}>
              <TextareaInputField
                placeholder={t('booking.WriteOtherSymptomsOrInformation')}
                size="lg"
                name="additionalInfo"
                classNames={{
                  input: classes.textinput,
                }}
              />
            </Box>
            <Button size="lg" radius="md" type="submit" w="100%">
              {t('booking.Book')}
            </Button>
          </Stack>
        </Form>
      </Formik>
    </Stack>
  );
};

const initialValuesBookAppointment: IBookAppointmentFormValues = {
  additionalInfo: '',
  date: '',
  time: '',
  format: '',
  symptoms: [],
};
