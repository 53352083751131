import { Image, Stack, Text, Box, Button, createStyles } from '@mantine/core';
import IconComingSoon from 'icons/IconComingSoon/Preferences-amico.svg';
import Icon404Error from 'icons/Icon404Error/404 error with portals-amico.svg';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES_LIST } from 'types';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles(() => ({
  root: {
    maxWidth: 430,
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    maxWidth: 400,
    height: 400,
    alignSelf: 'center',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    borderRadius: 8,
    width: '100%',
  },
}));

interface AlertFullPageProps {
  is404Error: boolean;
}

export const AlertFullPage = ({ is404Error = false }: AlertFullPageProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigateUser = useNavigate();
  const handleUserNavigate = () => {
    navigateUser(APP_ROUTES_LIST.HOME);
  };
  return (
    <Stack spacing={10} className={classes.root}>
      <Box className={classes.img}>
        <Image
          src={is404Error ? Icon404Error : IconComingSoon}
          alt="icon coming soon"
        />
      </Box>

      <Stack>
        <Text align="center">
          {is404Error
            ? t('alert.PageNotExistMessage')
            : t('alert.FeatureComingSoon')}
        </Text>

        <Box mt={20} className={classes.btnContainer}>
          <Button
            color="cyan"
            radius="md"
            size="md"
            className={classes.btn}
            onClick={handleUserNavigate}
          >
            {t('alert.ReturnToDashboard')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
