// notificationUtils.js
import { showNotification } from '@mantine/notifications';

export function showSuccessNotification(id, message, title = 'Success') {
  showNotification({
    id,
    color: 'teal',
    title,
    message,
    autoClose: 4000,
  });
}

export function showErrorNotification(id, message, title = 'Error') {
  showNotification({
    id,
    color: 'red',
    title,
    message,
    autoClose: 4000,
  });
}
