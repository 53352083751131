import ActionTypes from 'redux/action-types';
import API from 'redux/api-endpoints';
import { handleDeleteJson } from 'utils/http/delete-json';
import { handleGetJson } from 'utils/http/get-json';
import { handlePostJson } from 'utils/http/postjson';
import { handlePutJson } from 'utils/http/put-json';

export const handleFetchActiveUserPlanWithUserId = (
  idToken: string,
  userId: string,
) => {
  async () => {
    if (!userId) {
      return;
    }
  };

  return handleGetJson(
    idToken,
    API.getActiveUserPlanWithUserId(userId),
    ActionTypes.FETCH_ACTIVE_USER_PLAN_WITH_USERID_START,
    ActionTypes.FETCH_ACTIVE_USER_PLAN_WITH_USERID_SUCCESS,
    ActionTypes.FETCH_ACTIVE_USER_PLAN_WITH_USERID_ERROR,
    '',
  );
};

export const handleFetchUserPlanHistoryWithUserId = (
  idToken: string,
  userId: string,
) => {
  async () => {
    if (!userId) {
      return;
    }
  };
  return handleGetJson(
    idToken,
    API.getUserPlanHistoryWithUserId(userId),
    ActionTypes.FETCH_USER_PLAN_HISTORY_WITH_USERID_START,
    ActionTypes.FETCH_USER_PLAN_HISTORY_WITH_USERID_SUCCESS,
    ActionTypes.FETCH_USER_PLAN_HISTORY_WITH_USERID_ERROR,
    '',
  );
};

export const handleFetchAllBeneficiariesUserPlanId = (
  idToken: string,
  userPlanId: string,
) => {
  async () => {
    if (!userPlanId) {
      return;
    }
  };

  return handleGetJson(
    idToken,
    API.getBeneficiariesWithUserPlanId(userPlanId),
    ActionTypes.FETCH_BENEFICIARIES_WITH_USERPLAN_ID_START,
    ActionTypes.FETCH_BENEFICIARIES_WITH_USERPLAN_ID_SUCCESS,
    ActionTypes.FETCH_BENEFICIARIES_WITH_USERPLAN_ID_ERROR,
    '',
  );
};

export const handleFetchHealthPlansAndDurations = (idToken: string) => {
  return handleGetJson(
    idToken,
    API.getHealthPlanDurations(),
    ActionTypes.FETCH_HEALTH_PLANS_AND_DURATIONS_START,
    ActionTypes.FETCH_HEALTH_PLANS_AND_DURATIONS_SUCCESS,
    ActionTypes.FETCH_HEALTH_PLANS_AND_DURATIONS_ERROR,
    '',
  );
};

export const handleSelectedUserHealthPlans = (healthPlans: object) => {
  return {
    type: ActionTypes.SELECTED_USER_HEALTHPLAN_FOR_SUBSCRIPTION,
    payload: healthPlans,
  };
};
export const handleSelectedPlanDurationForSubscription = (
  planDuration: object,
) => {
  return {
    type: ActionTypes.SELECTED_PLAN_DURATION_FOR_SUBSCRIPTION,
    payload: planDuration,
  };
};

export const handleGeneratePaymentPlanReference = (
  idToken: string,
  data: object,
) => {
  return handlePostJson(
    API.generatePlanPaymentReferenceForPaystack(),
    data,
    idToken,
    ActionTypes.GENERATE_PAYMENT_PLAN_REFERENCE_START,
    ActionTypes.GENERATE_PAYMENT_PLAN_REFERENCE_SUCCESS,
    ActionTypes.GENERATE_PAYMENT_PLAN_REFERENCE_ERROR,
    '',
  );
};
export const handleCreateNewUserPlanWithPaymentReference = (
  idToken: string,
  data: object,
) => {
  return handlePostJson(
    API.postNewUserPlanWithUserIdAndPaymentReference(),
    data,
    idToken,
    ActionTypes.POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_START,
    ActionTypes.POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_SUCCESS,
    ActionTypes.POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_ERROR,
    'Your subscription is Successful',
  );
};
export const handleSelectedBeneficiaryForEditingSubscription = (
  selectedData: object,
) => {
  return {
    type: ActionTypes.SELECTED_BENEFICIARY_FOR_EDITING_SUBSCRIPTION,
    payload: selectedData,
  };
};

export const handleCreateNewBeneficiaryWithUserplanId = (
  idToken: string,
  data: object,
) => {
  return handlePostJson(
    API.postNewBeneficiaryWithUserPlanId(),
    data,
    idToken,
    ActionTypes.POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_START,
    ActionTypes.POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_SUCCESS,
    ActionTypes.POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_ERROR,
    'New beneficiary added Successfully',
  );
};

export const handleUpdateBeneficiaryWithPlanBeneficiaryId = (
  idToken: string,
  data: object,
) => {
  return handlePutJson(
    API.updateBeneficiaryWithPlanBeneficiaryId(),
    data,
    idToken,
    ActionTypes.UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START,
    ActionTypes.UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS,
    ActionTypes.UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR,
    'Beneficiary details updated successfully',
  );
};

export const handleDeleteBeneficiaryWithPlanBeneficiaryId = (
  idToken: string,
  planBeneficiaryId: string,
) => {
  return handleDeleteJson(
    API.deleteBeneficiaryWithPlanBeneficiaryId(planBeneficiaryId),
    null,
    idToken,
    ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START,
    ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS,
    ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR,
    'Beneficiary deleted successfully',
  );
};
export const handleDeleteUserPlanWithUserPlanId = (
  idToken: string,
  userplanId: string,
) => {
  return handleDeleteJson(
    API.deleteUserPlanWithUserPlanId(userplanId),
    null,
    idToken,
    ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START,
    ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS,
    ActionTypes.DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR,
    'Beneficiary deleted successfully',
  );
};
