import { Navigate, Outlet } from 'react-router-dom';
import { Stack, useMantineTheme } from '@mantine/core';
import { useAppSelector } from 'redux/hooks';
import { AppHeader } from 'components/AppHeader';
import { UserToUpgradeSubscription } from 'features/Appointments/components/AppointmentsTabs';
import { getPageDefaultStyles } from 'themes';
import { APP_ROUTES_LIST } from 'types';
import { useTranslation } from 'react-i18next';

export const BookAppointmentLayout = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { activeUserPlan } = useAppSelector(
    (state) => state?.subscriptionStore,
  );

  const { formDataForBooking } = useAppSelector(
    (state) => state?.appointmentStore,
  );

  if (!activeUserPlan?.planId) {
    return (
      <Stack
        sx={{
          ...getPageDefaultStyles(theme),
        }}
        spacing={theme.spacing.xl}
      >
        <AppHeader componentName={t('booking.BookConsultation')} />
        <UserToUpgradeSubscription
          activity={t('booking.UnableToBookAppointmentsNotSubscribed')}
        />
      </Stack>
    );
  }
  if (
    !formDataForBooking.date &&
    window.location.pathname !== APP_ROUTES_LIST.BOOKAPPOINTMENT
  ) {
    return <Navigate to={APP_ROUTES_LIST.BOOKAPPOINTMENT} />;
  }
  return <Outlet />;
};
