import {
  Box,
  Image,
  Stack,
  Button,
  Text,
  Title,
  Anchor,
  createStyles,
} from '@mantine/core';
import IconError from 'icons/IconError/Computer troubleshooting-pana.svg';

const useStyles = createStyles(() => ({
  root: {
    maxWidth: 430,
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    maxWidth: 200,
    height: 200,
    alignSelf: 'center',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    borderRadius: 8,
    width: '100%',
  },
}));

export const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  const { classes } = useStyles();
  return (
    <Stack spacing={10} className={classes.root}>
      <Box className={classes.img}>
        <Image src={IconError} alt="icon error" />
      </Box>

      <Stack>
        <Title align="center">Something went wrong</Title>

        <Box mt={20} className={classes.btnContainer}>
          <Button
            color="cyan"
            radius="md"
            size="md"
            className={classes.btn}
            onClick={resetErrorBoundary}
          >
            Try again
          </Button>

          <Text align="center" pt={'md'}>
            If issue persists,
            <Anchor
              href={`mailto:support@homnics.com?subject=Frontend error!!&body=This error occured: ${error.message}. Notify the frontend team to rectify this`}
              target="_blank"
              weight="bold"
            >
              please contact support here.
            </Anchor>
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
};
