import ActionTypes from 'redux/action-types';

const initialState = {
  submitting: false,
  success: false,
  error: false,
  reviewsData: null,
};

export const ReviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_START:
      return {
        ...state,
        submitting: false,
        success: false,
        error: false,
      };
    case ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        error: false,
        reviewsData: action.payload,
      };
    case ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_ERROR:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true,
      };
    default:
      return state;
  }
};
