import { BrandLogo } from 'components/BrandLogo';
import {
  AuthLayout,
  AuthLayoutLeftSide,
  AuthLayoutProps,
  AuthLayoutRightSide,
} from 'features/Auth/layouts/AuthLayout';

export interface SignInLayoutProps extends AuthLayoutProps {}

export const SignInLayout = ({
  children,
  ...otherProps
}: SignInLayoutProps) => {
  return (
    <AuthLayout {...otherProps}>
      <AuthLayoutLeftSide>
        <BrandLogo />
      </AuthLayoutLeftSide>

      <AuthLayoutRightSide spacing={'xl'}>{children}</AuthLayoutRightSide>
    </AuthLayout>
  );
};
