import { useAppSelector } from 'redux/hooks';

export const getAllMedicationsListFromReduxStore = () => {
  return useAppSelector((state) => state?.prescriptionStore?.medicationList);
};

export const getIsSubmittingFromPrescriptionReduxStore = () => {
  return useAppSelector((state) => state?.prescriptionStore?.submitting);
};

export const getIsSuccessfulFromPrescriptionReduxStore = () => {
  return useAppSelector((state) => state?.prescriptionStore?.success);
};

export const getIsErrorFromPrescriptionReduxStore = () => {
  return useAppSelector((state) => state?.prescriptionStore?.error);
};

export const getPatientPrescriptionsFromPrescriptionReduxStore = () => {
  return useAppSelector(
    (state) => state?.prescriptionStore?.patientPrescriptions,
  );
};

export const getSelectedPrescriptionIndexFromPrescriptionReduxStore = () => {
  return useAppSelector(
    (state) => state?.prescriptionStore?.selectedPrescriptionIndexOnTableRow,
  );
};

export const getSelectedPatientPrescriptionsFromPrescriptionReduxStore = () => {
  return useAppSelector(
    (state) => state?.prescriptionStore?.selectedPatientPrescription,
  );
};
