import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Stack, Group, Text, Anchor, createStyles } from '@mantine/core';
import { Card, CardProps } from 'components/Card';

const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    padding: '8px 24px 4px',
    width: '65vw',
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingTop: theme.fontSizes.sm,
    paddingBottom: theme.fontSizes.sm,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.secondary[0],
    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.sm,
      width: '100%',
    },
  },
  link: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    opacity: theme.colorScheme === 'dark' ? 0.6 : 1,
    textDecoration: 'none',
  },
  card: {
    borderRadius: 0,
    padding: '10px 20px',

    [theme.fn.smallerThan('md')]: {
      padding: theme.spacing.sm,
      fontSize: theme.fontSizes.sm,
    },
  },
  title: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.sm * 1.1,
    },
  },
  leftIcon: {
    fontSize: theme.fontSizes.md * 1.5,
    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.sm,
    },
  },
  rightIcon: {
    color: theme.colors.gray[6],
    textTransform: 'capitalize',
    fontSize: 24,
    fontWeight: 500,
    cursor: 'pointer',

    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.sm,
    },
  },
}));

interface SettingsCardArray {
  title: string;
  leftIcon: ReactNode;
  rightIcon: ReactNode | any;
  link: string;
}

export interface SettingsCardProps extends CardProps {
  settingsProps: {
    header: string;
    value: SettingsCardArray[];
  }[];
}

export const SettingsCard = ({
  settingsProps,
  ...otherProps
}: SettingsCardProps) => {
  const { classes } = useStyles();

  return (
    <Stack spacing={3}>
      {settingsProps.map((settingValues) => {
        const { header, value } = settingValues;
        return (
          <Stack spacing={3} key={settingValues.header}>
            <Text className={classes.header}>{header}</Text>{' '}
            {value.map((items) => {
              const { leftIcon, rightIcon, title, link } = items;
              return (
                <Card {...otherProps} className={classes.card} key={title}>
                  <Group position="apart">
                    {' '}
                    {link.startsWith('mailto:' || 'https:') ? (
                      <Anchor
                        href={link}
                        className={classes.link}
                        target="_blank"
                      >
                        <Group>
                          <Group className={classes.leftIcon}>{leftIcon}</Group>
                          <Text className={classes.title}>{title}</Text>
                        </Group>{' '}
                        {/* The Link tag overrides the icon so we need to probably find another way around this so the toggle btn is clickable as well as the entire component */}
                        {/* <Text className={classes.rightIcon}>{rightIcon}</Text> */}
                      </Anchor>
                    ) : (
                      <Link to={link} key={title} className={classes.link}>
                        <Group>
                          <Group className={classes.leftIcon}>{leftIcon}</Group>
                          <Text className={classes.title}>{title}</Text>
                        </Group>

                        {/* <Text className={classes.rightIcon}>{rightIcon}</Text> */}
                      </Link>
                    )}
                    <Text className={classes.rightIcon}>{rightIcon}</Text>
                  </Group>
                </Card>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};
