import { Grid, StackProps, useMantineTheme, Stack } from '@mantine/core';
import { AppHeader } from 'components/AppHeader';
import { BookAppointmentSearch } from 'features/BookAppointment/components/BookAppointmentSearch';
import { BookAppointmentFormContainer } from 'features/BookAppointment/components/BookAppointmentFormContainer';
import { useTranslation } from 'react-i18next';
import { getPageDefaultStyles } from 'themes';

export interface BookAppointmentFormPageProps extends StackProps {}

export const BookAppointmentFormPage = ({
  ...otherProps
}: BookAppointmentFormPageProps) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();

  return (
    <Stack
      {...otherProps}
      sx={{
        ...getPageDefaultStyles(theme),
      }}
    >
      <AppHeader />

      <Grid gutter={theme.spacing.md}>
        <Grid.Col md={12} lg={7}>
          <BookAppointmentSearch
            title={t('booking.BookConsultation')}
            hasCancelIcon={true}
            hasSearch={false}
          />
        </Grid.Col>
        <Grid.Col md={12} lg={7}>
          <BookAppointmentFormContainer />
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
