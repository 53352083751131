export interface Notification {
  id: string;
  title: string;
  description: string;
  date: string;
  status: 'read' | 'unread' | 'Active';
  type: NotificationTypes;
}

export enum NotificationTypes {
  Appointment = 'appointment',
  Message = 'message',
  Prescription = 'prescription',
  MedicalReviews = 'medical reviews',
}

export interface IUnreadNotification {
  id: string;
  userId: string;
  subject: string;
  content: string;
  dateCreated: string;
  dateOpened?: string;
  notificationType: string;
  notificationStatus: string;
}

export enum EUnreadNotificationStatus {
  Active = 'Active',
  Read = 'Read',
}
