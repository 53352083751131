import { combineReducers } from 'redux';
import { authReducer } from 'redux/auth/auth.reducer';
import { appointmentReducer } from 'redux/appointment';
import { profileReducer } from 'redux/profile';
import { sharedStoreReducer } from 'redux/sharedStore';
import { notificationReducer } from 'redux/notifications';
import { prescriptionsReducer } from 'redux/prescriptions';
import { subscriptionReducer } from 'redux/subscription';
import ActionTypes from './action-types';
import { ReviewsReducer } from 'redux/reviews';
import { recordsReducer } from 'redux/record';

const appReducer = combineReducers({
  authStore: authReducer,
  profileStore: profileReducer,
  appointmentStore: appointmentReducer,
  sharedStore: sharedStoreReducer,
  notificationStore: notificationReducer,
  prescriptionStore: prescriptionsReducer,
  subscriptionStore: subscriptionReducer,
  reviewsStore: ReviewsReducer,
  recordsStore: recordsReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === ActionTypes.USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
