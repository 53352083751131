import { IconMenu } from 'icons/IconMenu';
import { IconBrandLogo } from 'icons';
import { Box, Button, createStyles, useMantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom';
import { APP_ROUTES_LIST } from 'types';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.secondary[9] : theme.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: theme.fontSizes.sm * 0.5,
    paddingRight: 0,
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },
  logo: {
    fontSize: theme.fontSizes.xl * 1.5,
    fontWeight: 'bold',
  },
  menuButton: {
    backgroundColor: theme.colors.secondary[5],
    borderRadius: theme.fontSizes.md * 0.5,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
  },
}));

export interface NavbarMenuProps {
  handleToggleSidebar: () => void;
}

export const NavbarMenu = ({ handleToggleSidebar }: NavbarMenuProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  return (
    <Box className={classes.root}>
      <Link to={APP_ROUTES_LIST.HOME}>
        <IconBrandLogo
          className={classes.logo}
          fill={theme.black}
          homeFill={theme.colors.secondary[5]}
        />
      </Link>
      {/* <DarkModeToggle /> */}
      <Button onClick={handleToggleSidebar} className={classes.menuButton}>
        <IconMenu />
      </Button>
    </Box>
  );
};
