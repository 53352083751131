import { createStyles } from '@mantine/core';
import { TextInput } from 'components/Forms/TextInput';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useAppDispatch } from 'redux/hooks';
import { handleSearch } from 'redux/sharedStore';

const useStyles = createStyles(() => ({
  searchInput: {
    width: '100%',
    // width: '350px',
    // maxWidth: 427,
  },
}));

export const SearchInput = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(handleSearch(event.target.value));
  };

  // to clear the existing search on component mount
  useEffect(() => {
    dispatch(handleSearch(''));
  }, []);

  const { classes } = useStyles();
  return (
    <TextInput
      icon={<FiSearch />}
      placeholder={t('home.SearchFor')}
      className={classes.searchInput}
      onChange={handleSearchQuery}
    />
  );
};
