enum ActionTypes {
  START_LOGIN = 'START_LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  USER_LOGOUT = 'USER_LOGOUT',

  START_REGISTRATION = 'START_REGISTRATION',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
  REGISTRATION_ERROR = 'REGISTRATION_ERROR',

  CONFIRM_EMAIL_START = 'CONFIRM_EMAIL_START',
  CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR',

  NEWSLETTER_SUBSCRIPTION_START = 'NEWSLETTER_SUBSCRIPTION_START',
  NEWSLETTER_SUBSCRIPTION_SUCCESS = 'NEWSLETTER_SUBSCRIPTION_SUCCESS',
  NEWSLETTER_SUBSCRIPTION_ERROR = 'NEWSLETTER_SUBSCRIPTION_ERROR',

  GET_PASSWORD_TOKEN_START = 'GET_PASSWORD_TOKEN_START',
  GET_PASSWORD_TOKEN_SUCCESS = 'GET_PASSWORD_TOKEN_SUCCESS',
  GET_PASSWORD_TOKEN_ERROR = 'GET_PASSWORD_TOKEN_ERROR',
  PASSWORD_RESET_START = 'PASSWORD_RESET_START',
  PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR',

  CHANGE_PASSWORD_IN_APP_START = 'CHANGE_PASSWORD_IN_APP_START',
  CHANGE_PASSWORD_IN_APP_SUCCESS = 'CHANGE_PASSWORD_IN_APP_SUCCESS',
  CHANGE_PASSWORD_IN_APP_ERROR = 'CHANGE_PASSWORD_IN_APP_ERROR',

  GET_CURRENT_USER_DETAILS_START = 'GET_CURRENT_USER_DETAILS_START',
  GET_CURRENT_USER_DETAILS_SUCCESS = 'GET_CURRENT_USER_DETAILS_SUCCESS',
  GET_CURRENT_USER_DETAILS_ERROR = 'GET_CURRENT_USER_DETAILS_ERROR',

  UPLOAD_AVATAR_START = 'UPLOAD_AVATAR_START',
  UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_ERROR = 'UPLOAD_AVATAR_ERROR',

  UPDATE_PROFESSIONAL_DETAILS_START = 'UPDATE_PROFESSIONAL_DETAILS_START',
  UPDATE_PROFESSIONAL_DETAILS_SUCCESS = 'UPDATE_PROFESSIONAL_DETAILS_SUCCESS',
  UPDATE_PROFESSIONAL_DETAILS_ERROR = 'UPDATE_PROFESSIONAL_DETAILS_ERROR',
  UPDATE_CURRENT_USER_DETAILS_START = 'UPDATE_CURRENT_USER_DETAILS_START',
  UPDATE_CURRENT_USER_DETAILS_SUCCESS = 'UPDATE_CURRENT_USER_DETAILS_SUCCESS',
  UPDATE_CURRENT_USER_DETAILS_ERROR = 'UPDATE_CURRENT_USER_DETAILS_ERROR',

  FETCH_GENDER_LIST_START = 'FETCH_GENDER_LIST_START',
  FETCH_GENDER_LIST_SUCCESS = 'FETCH_GENDER_LIST_SUCCESS',
  FETCH_GENDER_LIST_ERROR = 'FETCH_GENDER_LIST_ERROR',
  FETCH_USER_RELATIONSHIP_LIST_START = 'FETCH_USER_RELATIONSHIP_LIST_START',
  FETCH_USER_RELATIONSHIP_LIST_SUCCESS = 'FETCH_USER_RELATIONSHIP_LIST_SUCCESS',
  FETCH_USER_RELATIONSHIP_LIST_ERROR = 'FETCH_USER_RELATIONSHIP_LIST_ERROR',

  FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_START = 'FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_START',
  FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_SUCCESS = 'FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_SUCCESS',
  FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_ERROR = 'FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_ERROR',

  BOOK_APPOINTMENT_FORM_DATA = 'BOOK_APPOINTMENT_FORM_DATA',
  SELECTED_PROFESSIONAL_FOR_APPOINTMENT_BOOKING = 'SELECTED_PROFESSIONAL_FOR_APPOINTMENT_BOOKING',
  SELECTED_SYMPTOMS_FOR_APPOINTMENT_BOOKING = 'SELECTED_SYMPTOMS_FOR_APPOINTMENT_BOOKING',

  FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_START = 'FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_START',
  FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_SUCCESS = 'FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_SUCCESS',
  FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_ERROR = 'FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_START',

  BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_START = 'BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_START',
  BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_SUCCESS = 'BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_SUCCESS',
  BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_ERROR = 'BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_ERROR',

  FETCH_APPOINTMENTS_START = 'FETCH_APPOINTMENTS_START',
  FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS',
  FETCH_APPOINTMENTS_ERROR = 'FETCH_APPOINTMENTS_ERROR',

  FETCH_PENDING_APPOINTMENTS_SUCCESS = 'FETCH_PENDING_APPOINTMENTS_SUCCESS',
  FETCH_SCHEDULED_APPOINTMENTS_SUCCESS = 'FETCH_SCHEDULED_APPOINTMENTS_SUCCESS',
  FETCH_DECLINED_APPOINTMENTS_SUCCESS = 'FETCH_DECLINED_APPOINTMENTS_SUCCESS',
  FETCH_COMPLETED_APPOINTMENTS_SUCCESS = 'FETCH_COMPLETED_APPOINTMENTS_SUCCESS',

  GENERATE_MEETING_JWT_START = 'GENERATE_MEETING_JWT_START',
  GENERATE_MEETING_JWT_SUCCESS = 'GENERATE_MEETING_JWT_SUCCESS',
  GENERATE_MEETING_JWT_ERROR = 'GENERATE_MEETING_JWT_ERROR',

  // reviews
  SELECTED_APPOINTMENT_TO_REVIEW = 'SELECTED_APPOINTMENT_TO_REVIEW',

  FETCH_REVIEWS_WITH_PROFESSIONALID_START = 'FETCH_REVIEWS_WITH_PROFESSIONALID_START',
  FETCH_REVIEWS_WITH_PROFESSIONALID_SUCCESS = 'FETCH_REVIEWS_WITH_PROFESSIONALID_SUCCESS',
  FETCH_REVIEWS_WITH_PROFESSIONALID_ERROR = 'FETCH_REVIEWS_WITH_PROFESSIONALID_ERROR',

  POST_NEW_REVIEW_START = 'POST_NEW_REVIEW_START',
  POST_NEW_REVIEW_SUCCESS = 'POST_NEW_REVIEW_SUCCESS',
  POST_NEW_REVIEW_ERROR = 'POST_NEW_REVIEW_ERROR',

  POST_PROFESSIONAL_REVIEW_START = 'POST_PROFESSIONAL_REVIEW_START',
  POST_PROFESSIONAL_REVIEW_SUCCESS = 'POST_PROFESSIONAL_REVIEW_SUCCESS',
  POST_PROFESSIONAL_REVIEW_ERROR = 'POST_PROFESSIONAL_REVIEW_ERROR',

  // prescriptions
  FETCH_ALL_MEDICATIONS_START = 'FETCH_ALL_MEDICATIONS_START',
  FETCH_ALL_MEDICATIONS_SUCCESS = 'FETCH_ALL_MEDICATIONS_SUCCESS',
  FETCH_ALL_MEDICATIONS_ERROR = 'FETCH_ALL_MEDICATIONS_ERROR',

  EXTRACT_PRESCRIPTION_FORM_TO_TABLE = 'EXTRACT_PRESCRIPTION_FORM_TO_TABLE',

  NEW_PRESCRIPTION_FOR_PATIENT_START = 'NEW_PRESCRIPTION_FOR_PATIENT_START',
  NEW_PRESCRIPTION_FOR_PATIENT_SUCCESS = 'NEW_PRESCRIPTION_FOR_PATIENT_SUCCESS',
  NEW_PRESCRIPTION_FOR_PATIENT_ERROR = 'NEW_PRESCRIPTION_FOR_PATIENT_ERROR',

  UPDATE_PATIENT_PRESCRIPTION_START = 'UPDATE_PATIENT_PRESCRIPTION_START',
  UPDATE_PATIENT_PRESCRIPTION_SUCCESS = 'UPDATE_PATIENT_PRESCRIPTION_SUCCESS',
  UPDATE_PATIENT_PRESCRIPTION_ERROR = 'UPDATE_PATIENT_PRESCRIPTION_START',

  FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_START = 'FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_START',
  FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_SUCCESS = 'FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_SUCCESS',
  FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_ERROR = 'FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_ERROR',

  SELECTED_PATIENT_PRESCRIPTION_FROM_TABLE = 'SELECTED_PATIENT_PRESCRIPTION_FROM_TABLE',
  SELECTED_PATIENT_PRESCRIPTION_ID_FROM_TABLE = 'SELECTED_PATIENT_PRESCRIPTION_ID_FROM_TABLE',

  // Notifications
  FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START',
  FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR',

  UPDATE_NOTIFICATIONS_TO_READ_START = 'UPDATE_NOTIFICATIONS_TO_READ_START',
  UPDATE_NOTIFICATIONS_TO_READ_SUCCESS = 'UPDATE_NOTIFICATIONS_TO_READ_SUCCESS',
  UPDATE_NOTIFICATIONS_TO_READ_ERROR = 'UPDATE_NOTIFICATIONS_TO_READ_ERROR',
  // subscriptions
  FETCH_HEALTH_PLANS_AND_DURATIONS_START = 'FETCH_HEALTH_PLANS_AND_DURATIONS_START',
  FETCH_HEALTH_PLANS_AND_DURATIONS_SUCCESS = 'FETCH_HEALTH_PLANS_AND_DURATIONS_SUCCESS',
  FETCH_HEALTH_PLANS_AND_DURATIONS_ERROR = 'FETCH_HEALTH_PLANS_AND_DURATIONS_ERROR',

  FETCH_ACTIVE_USER_PLAN_WITH_USERID_START = 'FETCH_ACTIVE_USER_PLAN_WITH_USERID_START',
  FETCH_ACTIVE_USER_PLAN_WITH_USERID_SUCCESS = 'FETCH_ACTIVE_USER_PLAN_WITH_USERID_SUCCESS',
  FETCH_ACTIVE_USER_PLAN_WITH_USERID_ERROR = 'FETCH_ACTIVE_USER_PLAN_WITH_USERID_ERROR',

  FETCH_USER_PLAN_HISTORY_WITH_USERID_START = 'FETCH_USER_PLAN_HISTORY_WITH_USERID_START',
  FETCH_USER_PLAN_HISTORY_WITH_USERID_SUCCESS = 'FETCH_USER_PLAN_HISTORY_WITH_USERID_SUCCESS',
  FETCH_USER_PLAN_HISTORY_WITH_USERID_ERROR = 'FETCH_USER_PLAN_HISTORY_WITH_USERID_ERROR',

  FETCH_BENEFICIARIES_WITH_USERPLAN_ID_START = 'FETCH_BENEFICIARIES_WITH_USERPLAN_ID_START',
  FETCH_BENEFICIARIES_WITH_USERPLAN_ID_SUCCESS = 'FETCH_BENEFICIARIES_WITH_USERPLAN_ID_SUCCESS',
  FETCH_BENEFICIARIES_WITH_USERPLAN_ID_ERROR = 'FETCH_BENEFICIARIES_WITH_USERPLAN_ID_ERROR',

  SELECTED_USER_HEALTHPLAN_FOR_SUBSCRIPTION = 'SELECTED_USER_HEALTHPLAN_FOR_SUBSCRIPTION',
  SELECTED_PLAN_DURATION_FOR_SUBSCRIPTION = 'SELECTED_PLAN_DURATION_FOR_SUBSCRIPTION',
  SELECTED_BENEFICIARY_FOR_EDITING_SUBSCRIPTION = 'SELECTED_BENEFICIARY_FOR_EDITING_SUBSCRIPTION',

  GENERATE_PAYMENT_PLAN_REFERENCE_START = 'GENERATE_PAYMENT_PLAN_REFERENCE_START',
  GENERATE_PAYMENT_PLAN_REFERENCE_SUCCESS = 'GENERATE_PAYMENT_PLAN_REFERENCE_SUCCESS',
  GENERATE_PAYMENT_PLAN_REFERENCE_ERROR = 'GENERATE_PAYMENT_PLAN_REFERENCE_ERROR',

  POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_START = 'POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_START',
  POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_SUCCESS = 'POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_SUCCESS',
  POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_ERROR = 'POST_NEW_USER_PLAN_WITH_PAYMENT_REFERENCE_ERROR',

  DELETE_USER_PLAN_WITH_USERPLAN_ID_START = 'DELETE_USER_PLAN_WITH_USERPLAN_ID_START',
  DELETE_USER_PLAN_WITH_USERPLAN_ID_SUCCESS = 'DELETE_USER_PLAN_WITH_USERPLAN_ID_SUCCESS',
  DELETE_USER_PLAN_WITH_USERPLAN_ID_ERROR = 'DELETE_USER_PLAN_WITH_USERPLAN_ID_ERROR',

  POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_START = 'POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_START',
  POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_SUCCESS = 'POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_SUCCESS',
  POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_ERROR = 'POST_NEW_BENEFICIARY_WITH_USERPLAN_ID_ERROR',

  UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START = 'UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START',
  UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS = 'UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS',
  UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR = 'UPDATE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR',

  DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START = 'DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_START',
  DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS = 'DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_SUCCESS',
  DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR = 'DELETE_BENEFICIARY_WITH_PLAN_BENEFICIARY_ID_ERROR',

  // shared features implementation
  SEARCH = 'SEARCH',

  // records
  GET_RECORDS_CATEGORY_START = 'GET_RECORDS_CATEGORY_START',
  GET_RECORDS_CATEGORY_SUCCESS = 'GET_RECORDS_CATEGORY_SUCCESS',
  GET_RECORDS_CATEGORY_ERROR = 'GET_RECORDS_CATEGORY_ERROR',

  ADD_RECORD_FILE_START = 'ADD_RECORD_FILE_START',
  ADD_RECORD_FILE_SUCCESS = 'ADD_RECORD_FILE_SUCCESS',
  ADD_RECORD_FILE_ERROR = 'ADD_RECORD_FILE_ERROR',

  ADD_NEW_RECORD_START = 'ADD_NEW_RECORD_START',
  ADD_NEW_RECORD_SUCCESS = 'ADD_NEW_RECORD_SUCCESS',
  ADD_NEW_RECORD_ERROR = 'ADD_NEW_RECORD_ERROR',

  GET_RECORDS_START = 'GET_RECORDS_START',
  GET_RECORDS_SUCCESS = 'GET_RECORDS_SUCCESS',
  GET_RECORDS_ERROR = 'GET_RECORDS_ERROR',

  GET_RECORD_BY_ID_START = 'GET_RECORD_BY_ID_START',
  GET_RECORD_BY_ID_SUCCESS = 'GET_RECORD_BY_ID_SUCCESS',
  GET_RECORD_BY_ID_ERROR = 'GET_RECORD_BY_ID_ERROR',

  DELETE_RECORD_START = 'DELETE_RECORD_START',
  DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS',
  DELETE_RECORD_ERROR = 'DELETE_RECORD_ERROR',
}

export default ActionTypes;
