import { Box, BoxProps, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    borderRadius: theme.radius.lg,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.secondary[8],
  },
}));

export interface CardProps extends BoxProps {}

export const Card = ({ className, ...otherProps }: CardProps) => {
  const { classes, cx } = useStyles();

  return <Box className={cx(classes.root, className)} {...otherProps} />;
};
