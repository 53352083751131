import { Group, Stack, Text } from '@mantine/core';
import { IPrescriptionData } from 'features/Prescriptions/types';
import { handleCleanAndFormatArrayProperty } from 'features/SearchProfessionals/helpers';

export const ProfessionalDataSignature = ({
  ...prescriptions
}: IPrescriptionData) => {
  const {
    professionalName,
    professionalEmail,
    professionalYearsOfExperience,
    professionalTitle,
    professionalSpecialties,
  } = prescriptions;
  return (
    <Stack spacing={0}>
      <Text weight={600}>{professionalName ? professionalName : ''}</Text>
      <Group>
        <Text>
          {handleCleanAndFormatArrayProperty(professionalSpecialties)}
        </Text>
        |<Text>{professionalTitle}</Text> *
      </Group>
      <Group>
        <Text>
          {professionalEmail} | {professionalYearsOfExperience}
        </Text>
      </Group>
    </Stack>
  );
};

export default ProfessionalDataSignature;
