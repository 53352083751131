import { useOidcIdToken } from '@axa-fr/react-oidc';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  handleCreateNewUserPlanWithPaymentReference,
  handleFetchActiveUserPlanWithUserId,
} from 'redux/subscription';
import { showErrorNotification, showSuccessNotification } from 'redux/utils';
import { handleFormatDateForBackend, handleFormatTimeForBackend } from 'utils';
import { APP_ROUTES_LIST } from 'types';
import { usePaystackPayment } from 'react-paystack';

const PAYSTACK_KEY = process.env.REACT_APP_PAYSTACK_PAYMENT_PUBLIC_TEST_KEY;

/**
 * The `usePaystackHook` hook provides utilities for handling Paystack payments
 * and creating new health plan subscriptions for users.
 *
 * It integrates with the Paystack API to process payments and dispatch actions
 * to create user plan subscriptions on successful payment.
 *
 * @returns {Object} An object containing functions and configuration for Paystack payment handling.
 */
export const usePaystackHook = () => {
  const dispatch = useAppDispatch();
  const { idToken } = useOidcIdToken();
  const { currentUserDetails } = useAppSelector((state) => state?.profileStore);
  const {
    paymentReferenceData,
    selectedHealthPlanForSubscription,
    selectedPlanDurationForSubscription,
  } = useAppSelector((state) => state?.subscriptionStore);

  const paystackPaymentConfig = {
    reference: paymentReferenceData?.paymentReference,
    email: currentUserDetails?.email,
    amount: parseInt(paymentReferenceData?.amount?.slice(0, -3), 10) * 100,
    publicKey: `${PAYSTACK_KEY}`,
  };

  const handleCreateNewHealthPlanForUser = async () => {
    try {
      const dataForNewHealthPlan = {
        userId: currentUserDetails?.userId,
        planId: selectedHealthPlanForSubscription?.id,
        planDurationId: selectedPlanDurationForSubscription?.id,
        startDate: `${handleFormatDateForBackend(
          new Date(),
        )}T${handleFormatTimeForBackend(new Date())}`,
        startImmediately: true,
        paymentReference: paymentReferenceData?.paymentReference,
      };
      await dispatch(
        handleCreateNewUserPlanWithPaymentReference(
          idToken,
          dataForNewHealthPlan,
        ),
      );
      await dispatch(
        handleFetchActiveUserPlanWithUserId(idToken, currentUserDetails.userId),
      );
    } catch (e: any) {
      console.error(e.message);
      showErrorNotification(
        'create-user-plan-error',
        'Something went wrong while trying to create your user plan. Please try again later',
        'Create user plan Error',
      );
    }
  };

  const navigateUserAfterPayment = useNavigate();
  const onPaystackSuccess = () => {
    // create userplan subscription to backend and redirect back to home
    handleCreateNewHealthPlanForUser();
    setTimeout(() => {
      navigateUserAfterPayment(APP_ROUTES_LIST.HOME);
    }, 2000);
  };

  const onPaystackClose = () => {
    showSuccessNotification(
      'cancel-paystack-dialog',
      'Plan subscription cancelled',
      'Alert',
    );
  };

  const initializePaystackHealthplanSubscription = usePaystackPayment(
    paystackPaymentConfig,
  );
  return {
    onPaystackClose,
    onPaystackSuccess,
    initializePaystackHealthplanSubscription,
    handleCreateNewHealthPlanForUser,
  };
};
