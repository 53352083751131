import { AlertFullPage } from 'components/AlertFullPage';
import { PatientPrescriptionTable } from 'features/Prescriptions/components/PrescriptionsTable/components/PatientPrescriptionTable';
import { IPrescriptionData } from 'features/Prescriptions/types';
import { PrescriptionTableContainerButtons } from 'features/Prescriptions/components/PrescriptionsTable/components/PrescriptionTableContainerButtons';
import { ProfessionalDataSignature } from 'features/Prescriptions/components/PrescriptionsTable/components/ProfessionalDataSignature';
import {
  Grid,
  createStyles,
  useMantineTheme,
  Box,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';
import { handleFormatDateWithoutTime } from 'utils';
import { IconBrandLogo } from 'icons';
import { useParams } from 'react-router';
import { usePDF } from 'react-to-pdf';
import { useAppSelector } from 'redux/hooks';

export const usePrescriptionContainerStyles = createStyles((theme) => ({
  root: {
    boxShadow:
      theme.colorScheme === 'dark'
        ? '0 0  2.6px #fff '
        : '0px 4px 4px rgba(0, 0, 0, 0.5)',
    paddingTop: theme.fontSizes.md,
    paddingBottom: theme.fontSizes.md,
    paddingLeft: theme.fontSizes.md * 1.1,
    paddingRight: theme.fontSizes.md * 1.1,
    borderRadius: theme.fontSizes.md,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  logo: {
    fontSize: theme.fontSizes.xl * 3,
    fontWeight: 'bold',
    [theme.fn.smallerThan('lg')]: {
      display: 'none',
    },
  },
  body: {
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.secondary[9]
        : theme.colors.secondary[0],
    paddingTop: theme.fontSizes.md,
    paddingBottom: theme.fontSizes.md,
    paddingLeft: theme.fontSizes.md * 1.9,
    paddingRight: theme.fontSizes.md * 1.9,
    borderRadius: theme.fontSizes.md,
    [theme.fn.smallerThan('lg')]: {
      padding: theme.fontSizes.sm * 0.5,
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  btn: {
    marginTop: 16,
    width: '100%',
  },
  loadingBtnContainer: {
    position: 'relative',
    width: `${theme.fontSizes.md}%`,
    padding: theme.fontSizes.md,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
export const PrescriptionTableContainer = () => {
  const { classes } = usePrescriptionContainerStyles();
  const theme = useMantineTheme();

  const { patientPrescriptions } = useAppSelector(
    (state) => state?.prescriptionStore,
  );

  // filter to match the useparams to render individual prescriptions
  const { id } = useParams();
  const filteredPrescriptions = patientPrescriptions?.filter(
    (prescriptions: IPrescriptionData) => prescriptions.id === id,
  );

  if (patientPrescriptions.length > 0 && filteredPrescriptions?.length === 0) {
    return <AlertFullPage is404Error={true} />;
  }
  const { toPDF, targetRef } = usePDF({ filename: 'prescription.pdf' });
  return (
    <div ref={targetRef}>
      <Stack className={classes.root}>
        {filteredPrescriptions.map((prescriptions: IPrescriptionData) => {
          const { id, beneficiaryName, dateAdded, symptoms } = prescriptions;
          return (
            <React.Fragment key={id}>
              <Grid>
                <Grid.Col md={12} lg={4}>
                  <Text weight={500}>{beneficiaryName}</Text>
                  {/* <Text>{`${beneficiaryGender}, ${handleCalculateAge(
                    beneficiaryDateOfBirth,
                  )}`}</Text> */}
                  <Text>{symptoms}</Text>
                </Grid.Col>

                <Grid.Col md={12} lg={4}>
                  <ProfessionalDataSignature {...prescriptions} />
                </Grid.Col>
                <Grid.Col md={12} lg={4}>
                  <Box className={classes.logoContainer}>
                    <IconBrandLogo
                      fill={
                        theme.colorScheme === 'dark' ? theme.white : theme.black
                      }
                      homeFill={theme.colors.secondary[3]}
                      className={classes.logo}
                    />
                  </Box>
                </Grid.Col>
              </Grid>

              <Stack className={classes.body} spacing={'xl'}>
                <Group position="center" mb={20}>
                  <Text>Date:</Text>
                  <Text weight={600}>
                    {handleFormatDateWithoutTime(dateAdded)}
                  </Text>
                </Group>
                <PatientPrescriptionTable
                  key={prescriptions.id}
                  {...prescriptions}
                />

                <PrescriptionTableContainerButtons
                  isPatientPrescriptionEditingToBackend={true}
                  {...prescriptions}
                  toPDF={toPDF}
                />
              </Stack>
            </React.Fragment>
          );
        })}
      </Stack>
    </div>
  );
};
