import { MedicationPrescription } from 'features/Prescriptions/types';
import { useAppSelector } from 'redux/hooks';

export const usePrescriptionHook = () => {
  const {
    medicationList: { medications = [] },
  } = useAppSelector((state) => state?.prescriptionStore);
  // Function to get the medication name from the given medicationId
  const handleGetMedicationNameWithMedicationId = (
    medicationId: string,
  ): string => {
    const matchedMedication = medications?.find(
      (medicationItem: { id: string }) => medicationItem?.id === medicationId,
    );
    return matchedMedication?.name || 'Unknown Medication';
  };

  const handleGetPrescribedMedicationArray = (
    medications: MedicationPrescription[],
  ) =>
    medications?.map((medication: MedicationPrescription) => {
      const { medicationId } = medication;
      const medicationName = handleGetMedicationNameWithMedicationId(
        medicationId as string,
      );
      return medicationName;
    });

  return {
    handleGetMedicationNameWithMedicationId,
    handleGetPrescribedMedicationArray,
  };
};
