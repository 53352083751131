import { Dispatch } from 'redux';
import {
  handleRequestErrors,
  showErrorNotification,
  showSuccessNotification,
} from 'redux/utils';

export const handleGetJson = (
  idToken: string,
  apiEndpoint: string,
  startActionType: string,
  successActionType: string,
  errorActionType: string,
  successMessage: '',
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: startActionType,
    });

    try {
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (!response.ok) {
        let errorObject;
        let errorMessage;
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          errorObject = await response.json();
          errorMessage = errorObject?.errors[0]?.message;
          console.error(errorObject?.errors[0]?.message);
        } else {
          errorMessage = await response.text();
        }
        dispatch({
          type: errorActionType,
          payload: errorMessage,
        });
        return showErrorNotification(
          'request-error',
          'Something went wrong with your request. Please try again later',
          'Error',
        );
      }

      // Handle the response as a success, whether it's JSON or not
      const data = await response.json().catch(() => null);
      if (successMessage) {
        await showSuccessNotification(
          successActionType,
          successMessage,
          'Successful',
        );
      }
      dispatch({
        type: successActionType,
        payload: data,
      });
    } catch (error) {
      const errorMessage = handleRequestErrors(error);
      // from redux utils
      showErrorNotification(errorActionType, errorMessage, 'Error');
      dispatch({
        type: errorActionType,
        payload: errorMessage,
      });
    }
  };
};
