import {
  Group,
  Button,
  Text,
  Input,
  createStyles,
  Textarea,
  Rating,
  Stack,
} from '@mantine/core';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  handlePostNewReviewAfterAppointment,
  handlePostProfessionalReview,
} from 'redux/reviews';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import { handleFormatDateForBackend, handleGetCurrentDate } from 'utils';
import { AppointmentDetailsProps } from 'features/Appointments/types';
import { FormEvent, useState } from 'react';

interface AppointmentReviewsModalProps extends AppointmentDetailsProps {
  closeModal: () => void;
}

const useStyles = createStyles((theme) => ({
  body: {
    [theme.fn.smallerThan('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    [theme.fn.largerThan('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    border: '1px solid',
    borderColor: theme.colors.cyan[1],
    backgroundColor: theme.colorScheme === 'dark' ? '' : theme.colors.cyan[0],
    borderRadius: theme.fontSizes.md,
    padding: theme.spacing.md * 0.6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export const AppointmentReviewsModal = ({
  closeModal,
  id,
  professionalId,
}: AppointmentReviewsModalProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { idToken } = useOidcIdToken();

  const { planBeneficiaries } = useAppSelector(
    (state) => state?.subscriptionStore,
  );
  const currentUserPlanBeneficiaryId = planBeneficiaries
    ? planBeneficiaries[0]?.id
    : null;
  const [ratingValue, setRatingValue] = useState(0);
  const [reviewValue, setReviewValue] = useState('');
  const [professionalRatingValue, setProfessionalRatingValue] = useState(0);
  const [professionalReviewValue, setProfessionalReviewValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const date = new Date(handleGetCurrentDate());

  const handleSubmitReview = async (e: FormEvent) => {
    e.preventDefault();
    if (!ratingValue || !reviewValue) {
      setErrorMessage('Choose a rating and write a review to continue ');
      return;
    }
    const data = {
      appointmentId: id,
      rating: ratingValue,
      review: reviewValue,
      dateAdded: `${handleFormatDateForBackend(date)}`,
      planBeneficiaryId: currentUserPlanBeneficiaryId,
    };

    const professionalData = {
      appointmentId: id,
      professionalId: professionalId,
      planBeneficiaryId: currentUserPlanBeneficiaryId,
      rating: professionalRatingValue,
      review: professionalReviewValue,
    };

    await Promise.all([
      dispatch(handlePostNewReviewAfterAppointment(data, idToken)),
      dispatch(handlePostProfessionalReview(professionalData, idToken)),
    ]);
    // await dispatch(handlePostNewReviewAfterAppointment(data, idToken));
    // await dispatch(handlePostProfessionalReview(professionalData, idToken));
    await closeModal();
  };
  return (
    <form onSubmit={handleSubmitReview}>
      <Input.Wrapper className={classes.body}>
        <Stack>
          <Text size={14} weight={600}>
            How was the session?
          </Text>
          <Rating value={ratingValue} onChange={setRatingValue} />
          <Textarea
            placeholder="Write your review here"
            variant="unstyled"
            autosize
            minRows={3}
            onChange={(e) => {
              setErrorMessage('');
              setReviewValue(e.target.value);
            }}
          />
        </Stack>
        <Stack>
          <Text size={14} weight={600}>
            How was the experience with the professional?
          </Text>
          <Rating
            value={professionalRatingValue}
            onChange={setProfessionalRatingValue}
          />
          <Textarea
            placeholder="Write your review here"
            variant="unstyled"
            autosize
            minRows={3}
            onChange={(e) => {
              setErrorMessage('');
              setProfessionalReviewValue(e.target.value);
            }}
          />
        </Stack>

        <Group position="right">
          <Button
            color="red"
            variant="light"
            radius="md"
            size="sm"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button color="cyan" type="submit" radius="md" size="sm">
            Send
          </Button>
        </Group>
      </Input.Wrapper>
      <Text size="sm" align="center" color="red" py={2}>
        {errorMessage}
      </Text>
    </form>
  );
};
