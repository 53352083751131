import ActionTypes from 'redux/action-types';

const initialState = {
  submitting: false,
  registerSuccess: false,
  loginSuccess: false,
  confirmEmailSuccess: false,
  passwordTokenSuccess: false,
  resetPasswordSuccess: false,
  changePasswordSuccess: false,
  success: false,
  error: false,
  userData: {},
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.LEAVE_REGISTRATION:
    // case ActionTypes.LEAVE_LOGIN:
    //   return {
    //     ...initialState,
    //   };

    case ActionTypes.START_REGISTRATION:
    case ActionTypes.START_LOGIN:
    case ActionTypes.CHANGE_PASSWORD_IN_APP_START:
    case ActionTypes.NEWSLETTER_SUBSCRIPTION_START:
    case ActionTypes.CONFIRM_EMAIL_START:
    case ActionTypes.PASSWORD_RESET_START:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false,
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        registerSuccess: false,
        loginSuccess: true,
        confirmEmailSuccess: false,
        passwordTokenSuccess: false,
        resetPasswordSuccess: false,
        changePasswordSuccess: false,
        error: false,
      };

    case ActionTypes.REGISTRATION_SUCCESS:
      return {
        ...state,
        submitting: false,
        registerSuccess: true,
        loginSuccess: false,
        confirmEmailSuccess: false,
        passwordTokenSuccess: false,
        resetPasswordSuccess: false,
        changePasswordSuccess: false,
        error: false,
        userData: action.payload,
      };
    case ActionTypes.GET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        submitting: false,
        registerSuccess: false,
        loginSuccess: false,
        confirmEmailSuccess: false,
        passwordTokenSuccess: true,
        resetPasswordSuccess: false,
        changePasswordSuccess: false,
        error: false,
      };
    case ActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        submitting: false,
        registerSuccess: false,
        loginSuccess: false,
        confirmEmailSuccess: false,
        passwordTokenSuccess: false,
        resetPasswordSuccess: true,
        changePasswordSuccess: false,
        error: false,
      };
    case ActionTypes.CHANGE_PASSWORD_IN_APP_SUCCESS:
      return {
        ...state,
        submitting: false,
        registerSuccess: false,
        loginSuccess: false,
        confirmEmailSuccess: false,
        passwordTokenSuccess: false,
        resetPasswordSuccess: false,
        changePasswordSuccess: true,
        error: false,
      };
    case ActionTypes.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        submitting: false,
        registerSuccess: false,
        loginSuccess: false,
        confirmEmailSuccess: true,
        passwordTokenSuccess: false,
        resetPasswordSuccess: false,
        changePasswordSuccess: false,
        error: false,
      };
    case ActionTypes.NEWSLETTER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        error: false,
      };
    case ActionTypes.LOGIN_ERROR:
    case ActionTypes.GET_PASSWORD_TOKEN_ERROR:
    case ActionTypes.PASSWORD_RESET_ERROR:
    case ActionTypes.CHANGE_PASSWORD_IN_APP_ERROR:
    case ActionTypes.NEWSLETTER_SUBSCRIPTION_ERROR:
    case ActionTypes.CONFIRM_EMAIL_ERROR:
    case ActionTypes.REGISTRATION_ERROR:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true,
      };
    default:
      return state;
  }
};
