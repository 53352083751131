import { Group, Grid, useMantineTheme, Stack, Title } from '@mantine/core';
import { SearchInput } from 'components/Forms/SearchInput';
import { AiOutlineClose } from 'react-icons/ai';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { APP_ROUTES_LIST } from 'types';

interface BookAppointmentSearchProps {
  title: string;
  hasSearch?: boolean;
  hasBackIcon?: boolean;
  hasCancelIcon?: boolean;
}

export const BookAppointmentSearch = ({
  title,
  hasSearch = true,
  hasBackIcon = true,
  hasCancelIcon = false,
}: BookAppointmentSearchProps) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  return (
    <Stack>
      <Grid gutter={theme.spacing.md * 0.5}>
        <Grid.Col>
          <Group position="apart">
            <span>
              {hasBackIcon && (
                <MdKeyboardArrowLeft
                  size={40}
                  cursor="pointer"
                  onClick={() => {
                    window.history.back();
                  }}
                />
              )}
            </span>
            <Title weight={500} order={2}>
              {title}
            </Title>
            <span>
              {hasCancelIcon && (
                <AiOutlineClose
                  size={30}
                  cursor="pointer"
                  onClick={() => {
                    navigate(APP_ROUTES_LIST.HOME);
                  }}
                />
              )}
            </span>
          </Group>
        </Grid.Col>
        <Grid.Col>{hasSearch && <SearchInput />}</Grid.Col>
      </Grid>
    </Stack>
  );
};
