import ActionTypes from 'redux/action-types';
import API from 'redux/api-endpoints';
import { handleRequestErrors, showErrorNotification } from 'redux/utils';
import { handleGetJson, handlePostJson } from 'utils/http';
import { handleDeleteJson } from 'utils/http/delete-json';

export const handleFetchRecordsCategory = (idToken) => {
  return handleGetJson(
    idToken,
    API.getRecordsCategory(),
    ActionTypes.GET_RECORDS_CATEGORY_START,
    ActionTypes.GET_RECORDS_CATEGORY_SUCCESS,
    ActionTypes.GET_RECORDS_CATEGORY_ERROR,
    '',
  );
};

export const handleAddNewRecord = (idToken, recordData) => {
  return handlePostJson(
    API.addNewRecord(),
    recordData,
    idToken,

    ActionTypes.ADD_NEW_RECORD_START,
    ActionTypes.ADD_NEW_RECORD_SUCCESS,
    ActionTypes.ADD_NEW_RECORD_ERROR,
    '',
  );
};
export const handleAddRecordFile = (idToken, recordFile) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ADD_RECORD_FILE_START });

    try {
      const fileObject = new FormData();
      fileObject.append('file', recordFile);

      const response = await fetch(API.addRecordFile(), {
        method: 'POST',
        body: fileObject,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (!response.ok) {
        return showErrorNotification(
          'upload-record-response-error',
          `Failed to add new record.`,
          'Error: adding new record',
        );
      }

      const data = await response.json();
      return dispatch({
        type: ActionTypes.ADD_RECORD_FILE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const errorMessage = handleRequestErrors(error);
      showErrorNotification(
        'upload-record-error',
        errorMessage,
        'Add Record image Error',
      );
      return dispatch({
        type: ActionTypes.ADD_RECORD_FILE_ERROR,
        payload: errorMessage,
      });
    }
  };
};

export const handleFetchRecords = (idToken, planBeneficiaryId) => {
  if (!planBeneficiaryId) {
    return async (dispatch) => {
      dispatch({ type: ActionTypes.GET_RECORDS_ERROR });
    };
  }
  return handleGetJson(
    idToken,
    API.getRecords(planBeneficiaryId),
    ActionTypes.GET_RECORDS_START,
    ActionTypes.GET_RECORDS_SUCCESS,
    ActionTypes.GET_RECORDS_ERROR,
    '',
  );
};

export const handleFetchRecordById = (idToken, recordId) => {
  return handleGetJson(
    idToken,
    API.getRecordById(recordId),
    ActionTypes.GET_RECORD_BY_ID_START,
    ActionTypes.GET_RECORD_BY_ID_SUCCESS,
    ActionTypes.GET_RECORD_BY_ID_ERROR,
    '',
  );
};

export const handleDeleteRecord = (idToken, recordId) => {
  return handleDeleteJson(
    API.deleteRecord(recordId),
    '',
    idToken,
    ActionTypes.DELETE_RECORD_START,
    ActionTypes.DELETE_RECORD_SUCCESS,
    ActionTypes.DELETE_RECORD_ERROR,
    '',
  );
};
