// import React, { useState, useEffect } from 'react';
// import { useMantineColorScheme, useMantineTheme } from '@mantine/core';
// import { BsMoon, BsSun } from 'react-icons/bs';
// import { Switch } from 'components';
// import { useSwitchToggleStyles } from 'features/Settings';

// export const DarkModeToggle: React.FC = () => {
//   const { classes } = useSwitchToggleStyles();
//   const [isDarkMode, setIsDarkMode] = useState(() => {
//     const storedDarkMode = localStorage.getItem('darkMode');
//     return storedDarkMode ? storedDarkMode === 'true' : false;
//   });

//   useEffect(() => {
//     localStorage.setItem('darkMode', String(isDarkMode));
//   }, [isDarkMode]);

//   const toggleDarkMode = () => {
//     setIsDarkMode(!isDarkMode);
//   };

//   const { colorScheme, toggleColorScheme } = useMantineColorScheme();
//   const theme = useMantineTheme();

//   useEffect(() => {
//     toggleColorScheme(isDarkMode ? 'dark' : 'light');
//   }, [isDarkMode, toggleColorScheme]);

//   return (
//     <Switch
//       checked={isDarkMode} // Use the local state to determine the checked state
//       onChange={toggleDarkMode}
//       size="lg"
//       onLabel={<BsSun color={theme.white} size="1.25rem" />}
//       offLabel={<BsMoon color={theme.colors.gray[6]} size="1.25rem" />}
//       color="dark"
//       className={classes.switch}
//     />
//   );
// };

import React, { useState, useEffect } from 'react';
import { useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { BsMoon, BsSun } from 'react-icons/bs';
import { Switch } from 'components/Forms/Switch';
import { useSwitchToggleStyles } from 'features/Settings/components/SettingsUIContainer';

export const DarkModeToggle: React.FC = () => {
  const { classes } = useSwitchToggleStyles();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    return storedDarkMode ? storedDarkMode === 'true' : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', String(isDarkMode));
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  useEffect(() => {
    toggleColorScheme(isDarkMode ? 'dark' : 'light');
  }, [isDarkMode, toggleColorScheme]);

  return (
    <Switch
      checked={colorScheme === 'dark'}
      onChange={toggleDarkMode}
      size="lg"
      onLabel={<BsSun color={theme.white} size="1.25rem" />}
      offLabel={<BsMoon color={theme.colors.gray[6]} size="1.25rem" />}
      color="dark"
      className={classes.switch}
    />
  );
};
