export interface IconBrandLogoProps extends React.SVGProps<SVGSVGElement> {
  homeFill?: string;
}

export const IconBrandLogo = ({
  fill = '#fff',
  homeFill = '#fff',
  ...otherProps
}: IconBrandLogoProps) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 81 72"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M11.418 42.092v.017c.353.657 21.034 20.124 29.506 20.124 8.472 0 29.123-19.465 29.478-20.124v-.017c.006 0 .006.006 0 .017-.055 4.364-20.872 28.936-29.478 28.936-8.608 0-29.461-23.698-29.506-28.936-.006-.01-.006-.017 0-.017Z"
      fill={fill}
    />
    <path
      d="M7.445 7.459c-8.73 8.73-8.73 22.885 0 31.615a22.43 22.43 0 0 0 3.842 3.082c-6.322-8.739-7.754-21.899.885-29.61 3.453-3.082 6.807-5.005 11.435-5.064 3.233-.042 5.023.938 7.954 2.303 3.255 1.516 7.552 5.244 7.552 5.244.24.192.786.55 1.503 1.02 1.972 1.291 5.234 3.428 6.949 5.274 2.338 2.518 7.193.72 5.035-3.057-1.347-2.358-6.121-6.46-10.97-9.89-2.755-1.95-5.74-3.766-7.599-4.701C25.537-1.003 14.646.258 7.445 7.459Z"
      fill={fill}
    />
    <path
      d="M74.378 7.367c8.73 8.73 8.73 22.885 0 31.616a22.433 22.433 0 0 1-3.842 3.081c6.322-8.739 7.754-21.898-.885-29.61-3.453-3.082-6.806-5.004-11.434-5.064-3.234-.041-5.023.938-7.955 2.303-3.255 1.516-7.551 5.245-7.551 5.245-.24.191-.787.55-1.504 1.019-1.972 1.292-5.234 3.428-6.949 5.275-2.337 2.517-7.193.719-5.035-3.058 1.348-2.357 6.122-6.46 10.97-9.89 2.756-1.95 5.74-3.766 7.599-4.701C56.286-1.095 67.177.166 74.378 7.367Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.62 7.653s-15.624 8.862-15.624 16.091c-.36 7.287-.36 10.524 0 17.178 2.706.504 6.49.875 10.586 1.074a5.587 5.587 0 0 1-.324-1.881v-5.597a5.597 5.597 0 0 1 11.194 0v5.597c0 .702-.13 1.374-.366 1.993 4.159-.12 7.972-.456 10.625-1.048.475-6.792.475-10.029 0-17.316 0-7.229-16.09-16.09-16.09-16.09Z"
      fill={homeFill}
    />
  </svg>
);
