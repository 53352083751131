import { Pagination } from '@mantine/core';
import React from 'react';

interface PaginationComponentProps {
  total: number;
  page: number;
  onChange: (page: number) => void;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  total,
  page,
  onChange,
}) => {
  return (
    <Pagination
      total={total}
      withEdges={true}
      page={page}
      onChange={onChange}
      styles={(theme) => ({
        item: {
          background: 'none',
          border: `1px solid ${theme.colors.brand[5]}`,
          margin: -4,
          '&[data-active]': {
            backgroundImage:
              theme.colorScheme === 'dark'
                ? theme.colors.white
                : theme.fn.gradient({
                    from: `${theme.colors.cyan}`,
                    to: `${theme.colors.brand[8]}`,
                  }),
          },
        },
      })}
    />
  );
};
