import { useOidcIdToken } from '@axa-fr/react-oidc';
import {
  Grid,
  Stack,
  Title,
  StackProps,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { Loading } from 'components/Loading';
import { PrescriptionTableContainer } from 'features/Prescriptions/components/PrescriptionsTable/components/PrescriptionTableContainer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleFetchAllMedicationsFromBackend } from 'redux/prescriptions';
import { getPageDefaultStyles } from 'themes';

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: theme.fontSizes.md * 1.5,
    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.lg * 1.1,
    },
  },
}));

export interface PrescriptionsTableProps extends StackProps {}

export const PrescriptionsTablePage = ({
  ...otherProps
}: PrescriptionsTableProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { idToken } = useOidcIdToken();
  const { t } = useTranslation();
  const { currentUserDetails } = useAppSelector((state) => state?.profileStore);
  const isSubmittingRequest = useAppSelector(
    (state) => state?.profileStore?.fetchSubmitting,
  );

  useEffect(() => {
    dispatch(handleFetchAllMedicationsFromBackend(idToken));
  }, [currentUserDetails?.userId]);

  return (
    <Stack
      {...otherProps}
      sx={{
        ...getPageDefaultStyles(theme),
      }}
    >
      <Title order={2} weight={500} className={classes.title}>
        {t('activity.YourPrescription')}
      </Title>

      <Grid gutter={theme.spacing.xl * 3.5}>
        <Grid.Col md={12} lg={12}>
          {isSubmittingRequest ? (
            <Loading variant="dots" />
          ) : (
            <PrescriptionTableContainer />
          )}
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
