import ChatForm from 'features/AISupport/components/ChatForm';
import { useEffect, useState } from 'react';
import { IMessage } from 'features/AISupport/types';
import { Box, createStyles } from '@mantine/core';
import ChatMessages from 'features/AISupport/components/ChatMessages';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    gap: theme.spacing.md,
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      gap: theme.spacing.xl,
    },
  },
  heading: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    color: theme.colors.dark[7],
    paddingBottom: theme.spacing.sm,
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      fontSize: theme.fontSizes.xl,
    },
  },
}));

const AISupport = () => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const { classes } = useStyles();

  const addMessage = (newMessage: IMessage) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];
      localStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
      return updatedMessages;
    });
  };

  useEffect(() => {
    const storedMessages = localStorage.getItem('chatMessages');
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
      return;
    }

    const initialMessage: IMessage = {
      id: 1,
      content: 'Hello! How can I assist you today about health matters?',
      timestamp: new Date().toISOString(),
      sender: 'support',
      status: 'unread',
    };
    addMessage(initialMessage);
  }, []);

  return (
    <Box className={classes.root}>
      <ChatMessages messages={messages} />
      <ChatForm onSendMessage={addMessage} messages={messages} />
    </Box>
  );
};

export default AISupport;
