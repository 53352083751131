import ActionTypes from 'redux/action-types';

const initialState = {
  success: false,
  error: false,
  submitting: false,
  recordsCategoryData: [],
  newRecord: null,
  recordsData: [],
  recordById: null,
  deletedRecordId: null,
  recordFileDetails: null,
};

export const recordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_RECORDS_CATEGORY_START:
    case ActionTypes.ADD_NEW_RECORD_START:
    case ActionTypes.ADD_RECORD_FILE_START:
    case ActionTypes.GET_RECORDS_START:
    case ActionTypes.GET_RECORD_BY_ID_START:
    case ActionTypes.DELETE_RECORD_START:
      return {
        ...state,
        success: false,
        error: false,
        submitting: true,
      };

    case ActionTypes.GET_RECORDS_CATEGORY_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        recordsCategoryData: action.payload.options,
      };
    case ActionTypes.ADD_NEW_RECORD_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        newRecord: action.payload,
      };
    case ActionTypes.ADD_RECORD_FILE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        recordFileDetails: action.payload,
      };
    case ActionTypes.GET_RECORDS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        recordsData: action.payload.records,
      };
    case ActionTypes.GET_RECORD_BY_ID_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        recordById: action.payload,
      };
    case ActionTypes.DELETE_RECORD_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        submitting: false,
        deletedRecordId: action.payload,
      };

    // Error Actions
    case ActionTypes.GET_RECORDS_CATEGORY_ERROR:
    case ActionTypes.ADD_NEW_RECORD_ERROR:
    case ActionTypes.ADD_RECORD_FILE_ERROR:
    case ActionTypes.GET_RECORDS_ERROR:
    case ActionTypes.GET_RECORD_BY_ID_ERROR:
    case ActionTypes.DELETE_RECORD_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        submitting: false,
      };

    default:
      return state;
  }
};
