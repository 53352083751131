import {
  Stack,
  Group,
  Text,
  Indicator,
  Modal,
  Button,
  Divider,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { Card } from 'components/Card';
import { UserStatuses } from 'types';
import { AppointmentDetailsProps } from 'features/Appointments/types';
import { AppointmentReviewsModal } from 'features/Appointments/components/AppointmentReviewsModal';
import { useAppointmentItem } from 'features/Appointments/hooks/useAppointmentItem';
import { ModalTitle } from 'components/ModalTitle';
import { handleFormatDate } from 'utils';
import { BiCalendar } from 'react-icons/bi';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
  root: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '',
    color: theme.colorScheme === 'dark' ? theme.white : '',
  },
  iconWrapper: {
    border: `3px solid ${theme.colors.gray[4]}`,
    borderRadius: '50%',
    padding: theme.fontSizes.sm * 0.7,
  },
  declined: {
    opacity: 0.8,
    zIndex: 0,
    background: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '',
    color: theme.colorScheme === 'dark' ? theme.white : '',
  },
}));

interface AppointmentItemProps extends AppointmentDetailsProps {}

export const AppointmentItem = ({
  ...appointmentProps
}: AppointmentItemProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { appointmentDate, symptoms, appointmentStatus, id, professionalName } =
    appointmentProps;
  const { isWithin30Minutes, handleStartMeeting } = useAppointmentItem();
  const isPending = appointmentStatus === UserStatuses.Pending;
  const isScheduled = appointmentStatus === UserStatuses.Scheduled;
  const isDeclined = appointmentStatus === UserStatuses.Declined;
  const isCompleted = appointmentStatus === UserStatuses.Completed;

  const [
    reviewModalOpened,
    { open: openReviewModal, close: closeReviewModal },
  ] = useDisclosure(false);

  return (
    <>
      <Card className={isDeclined ? classes.declined : classes.root}>
        <Group py={'sm'} position={'apart'}>
          <Group spacing={'xl'}>
            <Indicator color="blue" position="bottom-end" offset={10}>
              <Stack align="center" className={classes.iconWrapper}>
                <BiCalendar color={theme.colors.secondary[5]} size={25} />{' '}
              </Stack>
            </Indicator>

            <Stack spacing={3}>
              <Text weight={600}>
                {isCompleted
                  ? `${t('activity.YouHave')} Completed ${t(
                      'activity.AnAppointment',
                    )}`
                  : isScheduled
                  ? `${t('activity.YouHave')} a Scheduled appointment`
                  : isDeclined
                  ? `${t('activity.YourAppointmentWas')} Declined`
                  : `${t('activity.YourAppointmentIs')} Pending`}{' '}
              </Text>

              <Text weight={500}>
                {t('activity.With')} {professionalName}
              </Text>
              <Text size={14} weight={500}>
                {handleFormatDate(appointmentDate as string)}
              </Text>
              {isPending && (
                <Text size={14} weight={500}>
                  {symptoms?.slice(0, 25)}
                </Text>
              )}
            </Stack>
          </Group>

          {isWithin30Minutes(appointmentDate) && isScheduled && (
            <Button
              radius={'md'}
              color="green"
              variant="outline"
              onClick={() => handleStartMeeting(id)}
            >
              {t('activity.StartMeeting')}
            </Button>
          )}
          {isCompleted && (
            <Button
              radius={'md'}
              color="blue"
              variant="outline"
              onClick={() => {
                openReviewModal();
                // dispatch(
                //   handleSelectedAppointmentToReviewInRedux(appointmentProps),
                // );
                // setappointmentDateout(() => {
                //   navigateTo(APP_ROUTES_LIST.REVIEW_DETAIL);
                // }, 500);
              }}
            >
              {t('activity.AddReview')}
            </Button>
          )}
        </Group>
      </Card>
      <Divider />
      <Modal
        opened={reviewModalOpened}
        radius="md"
        onClose={closeReviewModal}
        size={'30rem'}
        title={<ModalTitle title="Review" />}
      >
        <AppointmentReviewsModal
          {...appointmentProps}
          closeModal={closeReviewModal}
        />
      </Modal>
    </>
  );
};
