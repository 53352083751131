import ActionTypes from 'redux/action-types';

const initialState = {
  currentUserDetails: {},
  success: false,
  error: false,
  profileAvatarSubmitting: false,
  profileFormSubmitting: false,
  fetchSubmitting: false,
  genderList: [],
  relationshipList: [],
  fetchedProfessionalsArray: [],
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CURRENT_USER_DETAILS_START:
      return {
        ...state,
        success: false,
        error: false,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: true,
      };
    case ActionTypes.GET_CURRENT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        currentUserDetails: action.payload,
      };
    case ActionTypes.GET_CURRENT_USER_DETAILS_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
      };

    case ActionTypes.UPLOAD_AVATAR_START:
      return {
        ...state,
        profileAvatarSubmitting: true,

        profileFormSubmitting: false,
        fetchSubmitting: false,
        success: false,
        error: false,
      };

    case ActionTypes.UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        success: true,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        error: false,
      };

    case ActionTypes.UPLOAD_AVATAR_ERROR:
      return {
        ...state,
        success: false,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        error: true,
      };
    case ActionTypes.UPDATE_CURRENT_USER_DETAILS_START:
      return {
        ...state,
        profileAvatarSubmitting: false,
        profileFormSubmitting: true,
        fetchSubmitting: false,
        success: false,
        error: false,
        currentUserDetails: { ...state.currentUserDetails, ...action.payload },
      };
    case ActionTypes.UPDATE_CURRENT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        success: true,
        error: false,
      };
    case ActionTypes.UPDATE_CURRENT_USER_DETAILS_ERROR:
      return {
        ...state,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        success: false,
        error: true,
      };
    case ActionTypes.GET_PROFESSIONAL_TYPES_SUCCESS:
      return {
        ...state,
        professionalTypesObj: action.payload,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        error: false,
        success: true,
      };
    case ActionTypes.GET_PROFESSIONAL_TYPES_ERROR:
      return {
        ...state,
        professionalTypesObj: action.payload,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        error: true,
        success: false,
      };
    case ActionTypes.FETCH_USER_RELATIONSHIP_LIST_SUCCESS:
      return {
        ...state,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        success: true,
        error: false,
        relationshipList: action.payload,
      };
    case ActionTypes.FETCH_GENDER_LIST_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitted: false,
        success: true,
        error: false,
        genderList: action.payload,
      };
    case ActionTypes.FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_START:
      return {
        ...state,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: true,
        error: false,
      };
    case ActionTypes.FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_SUCCESS:
      return {
        ...state,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        success: true,
        fetchedProfessionalsArray: action.payload,
        error: false,
      };
    case ActionTypes.FETCH_ALL_PROFESSIONALS_DETAILS_FROM_BACKEND_ERROR:
      return {
        ...state,
        isLoading: false,
        profileAvatarSubmitting: false,
        profileFormSubmitting: false,
        fetchSubmitting: false,
        error: true,
      };

    default:
      return state;
  }
};
