import { Dispatch } from 'redux';
import {
  handleRequestErrors,
  showErrorNotification,
  showSuccessNotification,
} from 'redux/utils';

export const handlePutJson = (
  endpoint: any,
  data: null | object = null,
  idToken: null | string = null,
  startActionType: string,
  successActionType: string,
  errorActionType: string,
  successMessage = '',
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: startActionType,
    });
    const headers: {
      'Content-Type': string;
      Authorization?: string | undefined;
    } = {
      'Content-Type': 'application/json',
    };

    if (idToken) {
      headers['Authorization'] = `Bearer ${idToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { 'Content-Type': string; Authorization?: string | undefined };
      body?: string;
    } = {
      method: 'PUT',
      headers,
    };

    if (data) {
      requestOptions.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(endpoint, requestOptions);

      if (!response.ok) {
        let errorObject;
        let errorMessage;
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          errorObject = await response.json();
          errorMessage = errorObject?.errors[0]?.message;
          console.error(errorObject?.errors[0]?.message);
        } else {
          errorMessage = await response.text();
        }
        dispatch({
          type: errorActionType,
          payload: errorMessage,
        });
        return showErrorNotification(
          'request-error',
          'Something went wrong with your request. Please try again later',
          'Error',
        );
      }

      let responseObj;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        responseObj = await response.json();
      } else {
        responseObj = await response.text();
      }
      await showSuccessNotification(
        successActionType,
        successMessage,
        'Successful',
      );
      return await dispatch({
        type: successActionType,
        payload: responseObj,
      });
    } catch (error) {
      const errorMessage = handleRequestErrors(error);
      // from redux utils
      showErrorNotification(errorActionType, errorMessage, 'Error');
      dispatch({
        type: errorActionType,
        payload: errorMessage,
      });
    }
  };
};
