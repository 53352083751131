import {
  createStyles,
  Divider,
  TabProps,
  Tabs,
  TabsProps,
  Text,
} from '@mantine/core';
import { Card } from 'components/Card';
import { APP_ROUTES_LIST, UserStatuses } from 'types';
import { Link } from 'components/Link';
import { SkeletonComponent } from 'components/SkeletonComponent';
import { useAppointmentTabs } from 'features/Appointments/hooks/useAppointmentTabs';
import { Appointments } from 'features/Appointments/components/Appointments';
import { AppointmentSelectedTabTypes } from 'features/Appointments/types';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useAppSelector } from 'redux/hooks';

export interface AppointmentsTabsProps extends Omit<TabsProps, 'children'> {}

export const useTabStyles = createStyles((theme, _params, getRef) => ({
  tabsList: {
    border: 'none',
    marginBottom: theme.spacing.md * 0.8,
  },
  tabRoot: {
    textTransform: 'capitalize',
    color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.black,
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[9]
        : theme.colors.secondary[0],

    // backgroundColor: theme.colors.secondary[0],
    opacity: 0.9,
    paddingBottom: 0,
    '&:hover': {
      opacity: 1,
      border: 'none',
      color:
        theme.colorScheme === 'dark' ? theme.white : theme.colors.secondary[9],
    },
    '&[data-active]': {
      border: 'none',
      borderLeft: `2px solid ${theme.colors.gray[2]}`,
      borderRight: `2px solid ${theme.colors.gray[2]}`,
      borderTop: `2px solid ${theme.colors.gray[2]}`,
      borderBottom: 'none',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      color: theme.colors.blue[5],
      [`& .${getRef('tab')}`]: {
        borderBottom: 'none',
        paddingBottom: 0,
      },
    },
  },
  tab: {
    borderBottom: `2px solid ${theme.colors.gray[2]}`,
    paddingBottom: theme.spacing.md * 0.4,
    ref: getRef('tab'),
  },
}));

export const AppointmentsTabs = ({ ...otherProps }: AppointmentsTabsProps) => {
  const { classes } = useTabStyles();
  const {
    selectedTab,
    tabLoading,
    handleTabClick,
    filteredAppointmentsArray,
    filteredAndSortedAppointmentsArray,
    isLoading,
  } = useAppointmentTabs();

  const {
    scheduledAppointmentsArray,
    declinedAppointmentsArray,
    completedAppointmentsArray,
    pendingAppointmentsArray,
  } = useAppSelector((state) => state?.appointmentStore);
  const { activeUserPlan } = useAppSelector(
    (state) => state?.subscriptionStore,
  );

  const { t } = useTranslation();

  const tabs: TabProps[] = [
    {
      children: t('activity.Scheduled'),
      value: UserStatuses.Scheduled,
    },
    {
      children: t('activity.Pending'),
      value: UserStatuses.Pending,
    },
    {
      children: t('activity.Declined'),
      value: UserStatuses.Declined,
    },
    {
      children: t('activity.Completed'),
      value: UserStatuses.Completed,
    },
  ];
  return (
    <Tabs defaultValue={selectedTab} {...otherProps}>
      <Tabs.List className={classes.tabsList} grow>
        {tabs.map(({ value, children }) => (
          <Tabs.Tab
            className={classes.tabRoot}
            key={value}
            value={value}
            onClick={() => handleTabClick(value as AppointmentSelectedTabTypes)}
          >
            <Text className={classes.tab}>{children}</Text>
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {tabs.map((tab) => {
        const selectedTabAppointmentsArray =
          tab.value === UserStatuses.Pending
            ? pendingAppointmentsArray
            : tab.value === UserStatuses.Scheduled
            ? scheduledAppointmentsArray
            : tab.value === UserStatuses.Declined
            ? declinedAppointmentsArray
            : tab.value === UserStatuses.Completed
            ? completedAppointmentsArray
            : [];

        return (
          <Tabs.Panel value={tab.value} key={tab.value}>
            {!activeUserPlan?.planId ? (
              <UserToUpgradeSubscription
                activity={t('activity.UnableToBook')}
              />
            ) : isLoading && tabLoading[tab.value] ? (
              <Card>
                <Text p={15}>
                  <SkeletonComponent height={8} radius={'xl'} />
                  <SkeletonComponent height={8} radius={'xl'} mt={10} />
                  <SkeletonComponent height={8} radius={'xl'} mt={10} />
                </Text>
              </Card>
            ) : (
              <>
                {/* todo - create a nothing to display component to be reused */}
                {selectedTabAppointmentsArray.length === 0 ? (
                  <Card>
                    <Text p={20}>{t('activity.NothingToDisplay')}</Text>
                    <Divider />
                  </Card>
                ) : (
                  <>
                    {filteredAndSortedAppointmentsArray(
                      filteredAppointmentsArray(selectedTabAppointmentsArray),
                    )?.length === 0 ? (
                      <Card>
                        <Text p={20}>{t('activity.NothingToDisplay')}</Text>
                      </Card>
                    ) : (
                      <Appointments
                        data={filteredAndSortedAppointmentsArray(
                          filteredAppointmentsArray(
                            selectedTabAppointmentsArray,
                          ),
                        )}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Tabs.Panel>
        );
      })}
    </Tabs>
  );
};

export const UserToUpgradeSubscription = ({
  activity,
}: {
  activity: string;
}) => {
  return (
    <Card>
      <Text p={20}>
        {activity}{' '}
        <Link to={APP_ROUTES_LIST.HEALTHPLANS}>
          {t('activity.ClickHereToUpgrade')}
        </Link>
      </Text>
      <Divider />
    </Card>
  );
};
