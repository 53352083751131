import React from 'react';
import { Button, Group, Stack, Title } from '@mantine/core';
import { ProfessionalDataSignature } from 'features/Prescriptions/components/PrescriptionsTable/components/ProfessionalDataSignature';
import { getIsSubmittingFromPrescriptionReduxStore } from 'redux/prescriptions';
import { IPrescriptionData } from 'features/Prescriptions/types';
import { useTranslation } from 'react-i18next';

interface PrescriptionContainerButtonsProps extends IPrescriptionData {
  closePrescriptionContainer?: () => void;
  isPatientPrescriptionEditingToBackend?: boolean;
  toPDF?: () => void;
}
export const PrescriptionTableContainerButtons = ({
  toPDF,
  ...prescriptions
}: PrescriptionContainerButtonsProps) => {
  const { t } = useTranslation();
  const { id } = prescriptions;
  const isCreatingPrescriptionSubmitting =
    getIsSubmittingFromPrescriptionReduxStore();

  return (
    <React.Fragment key={id}>
      <Group position="apart">
        <Group spacing={'xl'} mt={20}>
          <Button
            color="cyan"
            radius="md"
            size="md"
            type="submit"
            loading={isCreatingPrescriptionSubmitting}
            onClick={toPDF}
          >
            {t('activity.Download')}
          </Button>
          <Button
            color="cyan"
            radius="md"
            size="md"
            variant="outline"
            onClick={() => {
              window.history.back();
              window.history.back();
            }}
          >
            {t('activity.Close')}
          </Button>
        </Group>
        <Stack>
          <Title order={6} italic>
            Signature
          </Title>
          <ProfessionalDataSignature {...prescriptions} />
        </Stack>
      </Group>
    </React.Fragment>
  );
};
