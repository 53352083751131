import ActionTypes from 'redux/action-types';

const initialState = {
  loading: false,
  appointmentsArray: [],
  pendingAppointmentsArray: [],
  scheduledAppointmentsArray: [],
  declinedAppointmentsArray: [],
  completedAppointmentsArray: [],
  errorMessage: null,
  meeting: null,
  availableProfessionals: [],
  selectedProfessionalForBooking: {},
  formDataForBooking: {},
  selectedSymptomsForBooking: [],
};

export const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SELECTED_PROFESSIONAL_FOR_APPOINTMENT_BOOKING:
      return {
        ...state,
        selectedProfessionalForBooking: action.payload,
      };
    case ActionTypes.BOOK_APPOINTMENT_FORM_DATA:
      return {
        ...state,
        formDataForBooking: { ...state.formDataForBooking, ...action.payload },
      };
    case ActionTypes.SELECTED_SYMPTOMS_FOR_APPOINTMENT_BOOKING:
      return {
        ...state,
        selectedSymptomsForBooking: action.payload,
      };
    case ActionTypes.FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        availableProfessionals: action.payload.professionals,
      };
    case ActionTypes.FETCH_APPOINTMENTS_START:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ActionTypes.FETCH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentsArray: action.payload,
        errorMessage: null,
      };
    case ActionTypes.FETCH_APPOINTMENTS_ERROR:
    case ActionTypes.BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case ActionTypes.FETCH_PENDING_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        pendingAppointmentsArray: action.payload.appointments,
        loading: false,
        errorMessage: null,
      };
    case ActionTypes.FETCH_SCHEDULED_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        scheduledAppointmentsArray: action.payload.appointments,
        loading: false,
        errorMessage: null,
      };
    case ActionTypes.FETCH_DECLINED_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        declinedAppointmentsArray: action.payload.appointments,
        loading: false,
        errorMessage: null,
      };
    case ActionTypes.FETCH_COMPLETED_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        completedAppointmentsArray: action.payload.appointments,
        loading: false,
        errorMessage: null,
      };
    case ActionTypes.BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        appointmentsArray: action.payload,
        loading: false,
        errorMessage: null,
      };
    case ActionTypes.GENERATE_MEETING_JWT_START:
    case ActionTypes.BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_START:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ActionTypes.GENERATE_MEETING_JWT_SUCCESS:
      return {
        ...state,
        loading: false,
        meeting: action.payload,
        errorMessage: null,
      };
    case ActionTypes.GENERATE_MEETING_JWT_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        meeting: null,
      };
    default:
      return state;
  }
};
