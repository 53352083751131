import { Modal, createStyles } from '@mantine/core';
import { ModalTitle } from 'components/ModalTitle';
import { useDisclosure } from '@mantine/hooks';
import AISupport from '..';
import { BsChatDots } from 'react-icons/bs';

const useStyles = createStyles((theme) => ({
  root: {
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.secondary[9]
        : theme.colors.secondary[5],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    position: 'fixed',
    bottom: 10,
    right: 10,
    padding: theme.fontSizes.sm * 1.1,
    paddingTop: theme.fontSizes.sm,
    paddingBottom: theme.fontSizes.sm * 0.8,
    borderRadius: theme.fontSizes.lg,
    cursor: 'pointer',
  },
}));

const ChatIconContainer = () => {
  const { classes } = useStyles();
  const [chatModalOpened, { open: openChatModal, close: closeChatModal }] =
    useDisclosure(false);
  return (
    <>
      <div className={classes.root} onClick={openChatModal}>
        <BsChatDots size={30} />
      </div>
      <Modal
        opened={chatModalOpened}
        radius="md"
        size="xl"
        onClose={closeChatModal}
        title={<ModalTitle title="Chat with us" />}
      >
        <AISupport />
      </Modal>
    </>
  );
};

export default ChatIconContainer;
