import ActionTypes from 'redux/action-types';

const initialState = {
  medicationList: [],
  patientPrescriptions: [],
  submitting: false,
  success: false,
  error: false,
  selectedPrescriptionIndexOnTableRow: 0,
  selectedPatientPrescription: null,
};

export const prescriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_MEDICATIONS_START:
      return { ...state, submitting: false, success: false, error: false };
    case ActionTypes.FETCH_ALL_MEDICATIONS_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        error: false,
        medicationList: action.payload,
      };
    case ActionTypes.FETCH_ALL_MEDICATIONS_ERROR:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true,
      };
    case ActionTypes.NEW_PRESCRIPTION_FOR_PATIENT_START:
    case ActionTypes.UPDATE_PATIENT_PRESCRIPTION_START:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false,
      };
    case ActionTypes.NEW_PRESCRIPTION_FOR_PATIENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        error: false,
        patientPrescriptions: [...state.prescriptionTableData, action.payload],
      };
    case ActionTypes.NEW_PRESCRIPTION_FOR_PATIENT_ERROR:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true,
      };
    case ActionTypes.UPDATE_PATIENT_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        error: false,
      };

    case ActionTypes.FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_START:
      return {
        ...state,
        submitting: false,
        success: false,
        error: false,
      };
    case ActionTypes.FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        error: false,
        patientPrescriptions: action.payload,
      };
    case ActionTypes.FETCH_PATIENT_PRESCRIPTIONS_WITH_BENEFICIARYID_ERROR:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true,
      };
    case ActionTypes.SELECTED_PATIENT_PRESCRIPTION_FROM_TABLE: {
      const { selectedIndex, selectedPrescription } = action.payload;
      return {
        ...state,
        selectedPrescriptionIndexOnTableRow: selectedIndex,
        selectedPatientPrescription: selectedPrescription,
      };
    }

    default:
      return state;
  }
};
