import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useBookAppointmentValidationSchema = () => {
  const { t } = useTranslation();
  return Yup.object().shape({
    date: Yup.date().required(t('booking.DateRequired')),
    // .min(new Date(), t('booking.DateMustBeInFuture')),
    time: Yup.string().required(t('booking.TimeRequired')),
    format: Yup.string().required(t('booking.FormatRequired')),
  });
};
