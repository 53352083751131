import { MantineTheme, Sx } from '@mantine/core';

export const getPageDefaultStyles: (theme: MantineTheme) => Sx = (theme) => ({
  padding: theme.spacing.xl * 2,
  width: '100%',
  maxWidth: '1300px',
  [theme.fn.smallerThan('lg')]: {
    padding: theme.fontSizes.sm * 0.5,
  },
});
