import { ErrorMessagesEnum } from 'types';

export function handleRequestErrors(error) {
  if (error.message === 'Network Error') {
    return ErrorMessagesEnum.NetworkError;
  } else if (
    error.message === 'Failed to fetch' ||
    error.message === 'Failed to upload professional-licence Document: 400' ||
    error.message === 'Failed to upload identity-document Document: 400'
  ) {
    return ErrorMessagesEnum.UnknownError;
  } else if (error.message === 'Bad Request') {
    return ErrorMessagesEnum.InputDetailsError;
  } else {
    return 'An error occurred while processing your request. Please try again later';
  }
}
