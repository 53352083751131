export interface RouteObject {
  path: () => string;
}
export type RouteKeys =
  | 'IndexPage'
  | 'Register'
  // | 'SignIn'
  | 'Logout'
  | 'Verify'
  | 'VerifySuccess'
  | 'ResetPassword'
  | 'ResetPasswordSuccess'
  | 'RecoverPassword'
  | 'Unauthorized';

export const AuthRoutes: Record<RouteKeys, RouteObject> = {
  IndexPage: {
    path: () => '/',
  },
  // using external oidc for auth process
  // SignIn: {
  //   path: () => 'signin',
  // },
  Register: {
    path: () => 'register',
  },
  Logout: {
    path: () => 'logoff',
  },
  Verify: {
    path: () => 'confirm-email',
  },
  VerifySuccess: {
    path: () => 'confirm-email-success',
  },
  RecoverPassword: {
    path: () => 'forgot-password',
  },
  ResetPassword: {
    path: () => 'reset-password',
  },
  ResetPasswordSuccess: {
    path: () => 'reset-password-success',
  },
  Unauthorized: {
    path: () => 'unauthorized',
  },
};
