import { useOidc, useOidcFetch } from '@axa-fr/react-oidc';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { Loading } from 'components/Loading';
import { Stack } from '@mantine/core';
import { handleFetchCurrentUserDetails } from 'redux/profile';

export const CallbackSuccessComponent = () => {
  const { fetch: oidcFetch } = useOidcFetch();
  const { logout } = useOidc();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(handleFetchCurrentUserDetails(oidcFetch, logout));
  }, []);

  return (
    <Stack align="center">
      <Loading
        size="xl"
        variant="bars"
        text="Authentication successful. You will be redirected to the app."
        height="20vh"
      />
    </Stack>
  );
};
