import ActionTypes from 'redux/action-types';
import API from 'redux/api-endpoints';
import { handleGetJson } from 'utils/http';
import { handlePostJson } from 'utils/http/postjson';

export const handleFetchAvailableProfessionalsForAppointment = (
  appointmentDate,
  idToken,
) => {
  return handleGetJson(
    idToken,
    API.getAvailableProfessionalsForAppointments(appointmentDate),
    ActionTypes.FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_START,
    ActionTypes.FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_SUCCESS,
    ActionTypes.FETCH_AVAILABLE_PROFESSIONALS_FOR_APPOINTMENT_ERROR,
  );
};

export const handleFetchUsersAppointmentsByStatus = (
  beneficiaryId,
  status,
  idToken,
) => {
  if (!beneficiaryId) {
    return;
  }

  let successActionTypeForFetchingAppointment;

  switch (status) {
    case 1:
      successActionTypeForFetchingAppointment =
        ActionTypes.FETCH_PENDING_APPOINTMENTS_SUCCESS;
      break;
    case 2:
      successActionTypeForFetchingAppointment =
        ActionTypes.FETCH_SCHEDULED_APPOINTMENTS_SUCCESS;
      break;
    case 3:
      successActionTypeForFetchingAppointment =
        ActionTypes.FETCH_DECLINED_APPOINTMENTS_SUCCESS;
      break;
    case 4:
      successActionTypeForFetchingAppointment =
        ActionTypes.FETCH_COMPLETED_APPOINTMENTS_SUCCESS;
      break;
    default:
      successActionTypeForFetchingAppointment =
        ActionTypes.FETCH_APPOINTMENTS_SUCCESS;
  }
  return handleGetJson(
    idToken,
    API.getUsersAppointmentByStatusDetails(beneficiaryId, status),
    ActionTypes.FETCH_APPOINTMENTS_START,
    successActionTypeForFetchingAppointment,
    ActionTypes.FETCH_APPOINTMENTS_ERROR,
  );
};
export const handleCreateNewAppointmentWithProfessional = (data, idToken) => {
  return handlePostJson(
    API.postNewAppointment(),
    data,
    idToken,
    ActionTypes.BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_START,
    ActionTypes.BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_SUCCESS,
    ActionTypes.BOOK_NEW_APPOINTMENT_WITH_PROFESSIONAL_ERROR,
    'Your Appointment is booked Successfully',
  );
};

export const generateMeetingJwt = (appointmentId, idToken) => {
  return handlePostJson(
    API.generateMeetingJwt(),
    { appointmentId },
    idToken,
    ActionTypes.GENERATE_MEETING_JWT_START,
    ActionTypes.GENERATE_MEETING_JWT_SUCCESS,
    ActionTypes.GENERATE_MEETING_JWT_ERROR,
    '',
  );
};

export const handleBookAppointmentFormData = (data) => {
  return {
    type: ActionTypes.BOOK_APPOINTMENT_FORM_DATA,
    payload: data,
  };
};

export const handleSelectedProfessionalForAppointmentBooking = (
  selectedProfessional,
) => {
  return {
    type: ActionTypes.SELECTED_PROFESSIONAL_FOR_APPOINTMENT_BOOKING,
    payload: selectedProfessional,
  };
};

export const handleSelectedSymptomsForAppointmentBooking = (symptoms) => {
  return {
    type: ActionTypes.SELECTED_SYMPTOMS_FOR_APPOINTMENT_BOOKING,
    payload: symptoms,
  };
};
