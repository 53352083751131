import { Box, Button, Group, createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { BrandLogo } from 'components/BrandLogo';
import { Sidebar, SidebarMenu } from 'components/Sidebar';
import { ILinkItem } from 'types/ILinkItem';
import { FiGlobe, FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { BsClockHistory, BsPersonCircle } from 'react-icons/bs';
import { CiSearch } from 'react-icons/ci';
import { APP_ROUTES_LIST } from 'types';
import { useOidc } from '@axa-fr/react-oidc';
import { FaFileSignature, FaRegCalendarAlt } from 'react-icons/fa';
import { RiFirstAidKitLine } from 'react-icons/ri';
import { GoHome } from 'react-icons/go';

const useStyles = createStyles((theme) => ({
  root: {
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.secondary[9]
        : theme.colors.secondary[0],

    // zIndex: 1000, this was reduced on smaller screen sizes but just leave it here in case there is a reason it was left behind
    minHeight: '100vh',
    paddingLeft: theme.spacing.md * 1.5,
    paddingRight: theme.spacing.md * 1.5,
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    justifyContent: 'space-between',
  },

  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    fontSize: theme.fontSizes.xl * 2,
    color: theme.black,
  },
  logoText: {
    fontSize: theme.fontSizes.xl * 1.2,
    fontWeight: 500,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
  logoButton: {
    backgroundColor: theme.colors.secondary[5],
    opacity: 0.7,
    border: '1px solid',
    borderColor: theme.colors.gray[0],
    borderRadius: theme.fontSizes.md * 0.5,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },
  routerLink: {
    textDecoration: 'none',
  },
  logoutButton: {
    color:
      theme.colorScheme === 'dark' ? theme.white : theme.colors.secondary[9],
    opacity: 0.6,
    width: 'fit-content',
    padding: 0,
    '&:hover': {
      background: 'none',
      color:
        theme.colorScheme === 'dark' ? theme.white : theme.colors.secondary[9],
      opacity: 1,
    },
  },
}));

export interface SidebarContainerProps {
  handleToggleSidebar: () => void;
  setIsSidebarVisible: any;
  isSidebarVisible: boolean;
}

export const SidebarContainer = ({
  handleToggleSidebar,
  isSidebarVisible,
  setIsSidebarVisible,
}: SidebarContainerProps) => {
  const { classes } = useStyles();

  const { logout } = useOidc();
  const handleLogout = () => {
    logout('/');
  };

  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();
  const handleEnglishLanguage = () => {
    changeLanguage('en');
  };

  const handleFrenchLanguage = () => {
    changeLanguage('fr');
  };

  const sidebarLinkItems: ILinkItem[] = [
    {
      label: t('sidebar.Home'),
      href: APP_ROUTES_LIST.HOME,
      Icon: () => <GoHome size={28} />,
    },
    {
      label: t('sidebar.Search'),
      href: APP_ROUTES_LIST.SEARCH,
      Icon: () => <CiSearch size={28} />,
    },
    {
      label: t('sidebar.BookAppointment'),
      href: APP_ROUTES_LIST.BOOKAPPOINTMENT,
      Icon: () => <BsClockHistory size={28} />,
    },
    {
      label: t('sidebar.Appointments'),
      href: APP_ROUTES_LIST.APPOINTMENTS,
      Icon: () => <FaRegCalendarAlt size={28} />,
    },
    {
      label: t('sidebar.Prescriptions'),
      href: APP_ROUTES_LIST.PRESCRIPTIONS,
      Icon: () => <RiFirstAidKitLine size={28} />,
    },
    {
      label: t('sidebar.Records'),
      href: APP_ROUTES_LIST.RECORDS,
      Icon: () => <FaFileSignature size={28} />,
    },
    {
      label: t('sidebar.Account'),
      href: APP_ROUTES_LIST.ACCOUNT,
      Icon: () => <BsPersonCircle size={28} />,
    },
  ];

  return (
    <Sidebar className={classes.root}>
      <Box className={classes.logoContainer}>
        <Link to={APP_ROUTES_LIST.HOME} className={classes.routerLink}>
          <BrandLogo
            orientation="horizontal"
            containerProps={{ className: classes.logo, spacing: 'md' }}
            textProps={{ className: classes.logoText }}
          />
        </Link>

        <Group style={{ cursor: 'pointer' }}>
          <span onClick={handleEnglishLanguage}>En</span>
          <FiGlobe />
          <span onClick={handleFrenchLanguage}>Fr</span>
        </Group>
        {isSidebarVisible && (
          <Button onClick={handleToggleSidebar} className={classes.logoButton}>
            X
          </Button>
        )}
      </Box>

      {/* function setIsSidebarVisible passed on from the AppLayout function */}
      <SidebarMenu
        items={sidebarLinkItems}
        onClick={() => setIsSidebarVisible(false)}
      />

      <Button
        variant="subtle"
        size="lg"
        className={classes.logoutButton}
        leftIcon={<FiLogOut size={28} />}
        onClick={handleLogout}
      >
        {t('sidebar.Logout')}
      </Button>
    </Sidebar>
  );
};
