import ActionTypes from 'redux/action-types';
import API from 'redux/api-endpoints';
import { handleGetJson } from 'utils/http';
import { handlePostJson } from 'utils/http/postjson';

export const handleFetchReviewsWithPlanBeneficiaryId = (
  planBeneficiaryId,
  idToken,
) => {
  async () => {
    if (!planBeneficiaryId) {
      return;
    }
  };

  return handleGetJson(
    idToken,
    API.getReviewsWithPlanBeneficiaryId(planBeneficiaryId),
    ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_START,
    ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_SUCCESS,
    ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_ERROR,
    '',
  );
};
export const handleFetchReviewsWithProfessionalId = (
  professionalId,
  idToken,
) => {
  async () => {
    if (!professionalId) {
      return;
    }
  };

  return handleGetJson(
    idToken,
    API.getReviewsWithProfessionalId(professionalId),
    ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_START,
    ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_SUCCESS,
    ActionTypes.FETCH_REVIEWS_WITH_PROFESSIONALID_ERROR,
    '',
  );
};
export const handlePostNewReviewAfterAppointment = (data, idToken) => {
  return handlePostJson(
    API.postNewAppointmentReview(),
    data,
    idToken,
    ActionTypes.POST_NEW_REVIEW_START,
    ActionTypes.POST_NEW_REVIEW_SUCCESS,
    ActionTypes.POST_NEW_REVIEW_ERROR,
    'Appointment Review added Successfully',
  );
};
export const handlePostProfessionalReview = (data, idToken) => {
  return handlePostJson(
    API.postProfessionalReview(),
    data,
    idToken,
    ActionTypes.POST_PROFESSIONAL_REVIEW_START,
    ActionTypes.POST_PROFESSIONAL_REVIEW_SUCCESS,
    ActionTypes.POST_PROFESSIONAL_REVIEW_ERROR,
    'Professional Review added Successfully',
  );
};
