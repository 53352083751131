import ActionTypes from 'redux/action-types';
import { handleGetJson, handlePostJson, handlePutJson } from 'utils/http';
import API from 'redux/api-endpoints';

export const handleGetPasswordResetToken = (email) => {
  return handleGetJson(
    null,
    API.getPasswordToken(email),
    ActionTypes.GET_PASSWORD_TOKEN_START,
    ActionTypes.GET_PASSWORD_TOKEN_SUCCESS,
    ActionTypes.GET_PASSWORD_TOKEN_ERROR,
    'Please check your email for the token to reset your password.',
  );
};

export const handlePasswordReset = (data) => {
  return handlePutJson(
    API.resetPassword(),
    data,
    null,
    ActionTypes.PASSWORD_RESET_START,
    ActionTypes.PASSWORD_RESET_SUCCESS,
    ActionTypes.PASSWORD_RESET_ERROR,
    'New Password Updated successfully. Log in with your new password to continue',
  );
};
export const handleChangePasswordInApp = (data, userId, idToken) => {
  if (!userId) {
    return;
  }
  return handlePutJson(
    API.changePasswordInApp(userId),
    data,
    idToken,
    ActionTypes.CHANGE_PASSWORD_IN_APP_START,
    ActionTypes.CHANGE_PASSWORD_IN_APP_SUCCESS,
    ActionTypes.CHANGE_PASSWORD_IN_APP_ERROR,
    'New Password Updated successfully',
  );
};
export const submitRegistration = (data) => {
  return handlePostJson(
    API.register(),
    data,
    null,
    ActionTypes.START_REGISTRATION,
    ActionTypes.REGISTRATION_SUCCESS,
    ActionTypes.REGISTRATION_ERROR,
    'Sign up successful. Check your email to verify your account',
  );
};

export function handleUserLogOut() {
  return {
    type: ActionTypes.USER_LOGOUT,
  };
}
export const handleNewsletterSubscription = (data) => {
  return handlePostJson(
    API.subscribeToNewsLetter(),
    data,
    null,
    ActionTypes.NEWSLETTER_SUBSCRIPTION_START,
    ActionTypes.NEWSLETTER_SUBSCRIPTION_SUCCESS,
    ActionTypes.NEWSLETTER_SUBSCRIPTION_ERROR,
    'Email Subscription successful.',
  );
};
export const handleConfirmEmailAfterAccountCreation = (userId, mobileToken) => {
  return handlePostJson(
    API.confirmEmail(userId, mobileToken),
    null,
    null,
    ActionTypes.CONFIRM_EMAIL_START,
    ActionTypes.CONFIRM_EMAIL_SUCCESS,
    ActionTypes.CONFIRM_EMAIL_ERROR,
    'Your account has been confirmed',
  );
};
