import { Title } from '@mantine/core';

interface ModalTitleProps {
  title: string;
}
export const ModalTitle = ({ title }: ModalTitleProps) => {
  return (
    <Title size={24} weight={500} pl={'sm'}>
      {title}
    </Title>
  );
};
