import { useField } from 'formik';
import { Select, SelectProps } from '@mantine/core';

interface FormikSelectProps extends SelectProps {
  name: string;
  placeholder: string;
  options: {
    value: string;
    label: string;
  }[];
}

export const SelectInputField = (props: FormikSelectProps) => {
  const [field, meta, helpers] = useField(props.name);

  const { onBlur, onChange, placeholder, ...otherProps } = props;

  const handleSelectChange = (value: string) => {
    helpers.setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleSelectBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    helpers.setTouched(true);
    onBlur && onBlur(event);
  };

  return (
    <Select
      {...otherProps}
      value={field.value}
      onChange={handleSelectChange}
      onBlur={handleSelectBlur}
      error={meta.touched && meta.error}
      placeholder={placeholder}
      creatable
    />
  );
};
