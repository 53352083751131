import { Button, Group, Text, createStyles } from '@mantine/core';
import { Link } from 'react-router-dom';

const useStyles = createStyles(() => ({
  link: {
    color: 'white',
  },
}));

export const BannerIncompleteAccount = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { classes } = useStyles();
  return (
    <Group align="center" noWrap position="apart" px="xl" bg="#36454f">
      <Group>
        <Text size="md" color="white">
          Finish setting up your account to enjoy the features.
        </Text>
        <Link to="/profile" className={classes.link}>
          Complete your account
        </Link>
      </Group>

      <Button color="white" size="md" variant="subtle" onClick={onClose}>
        X
      </Button>
    </Group>
  );
};
