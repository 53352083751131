import { BrowserRouter as Router } from 'react-router-dom';
import { AppProvider } from 'providers';
import { Loading } from 'components/Loading';
import { ErrorFallback } from 'routes';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';
import { AppRoutes } from 'routes/AppRoutes';

const App = () => {
  return (
    <AppProvider>
      <Suspense fallback={<Loading size="xl" variant="dots" height="30vh" />}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Router>
            <AppRoutes />
          </Router>
        </ErrorBoundary>
      </Suspense>
    </AppProvider>
  );
};

export default App;
