export const routePrefix = 'user-api'; // TODO - move to config
// eslint-disable-next-line no-undef
export const homnicsApiUrl = process.env.REACT_APP_HOMNICS_ENDPOINT;

const API = {
  subscribeToNewsLetter: () => `${homnicsApiUrl}/${routePrefix}/subscribe`,
  register: () => `${homnicsApiUrl}/${routePrefix}/account`,
  confirmEmail: (userId, mobileToken) =>
    `${homnicsApiUrl}/${routePrefix}/account/${userId}/confirm-email?mobileToken=${mobileToken}`,
  getPasswordToken: (email) =>
    `${homnicsApiUrl}/${routePrefix}/account/reset-password-token?email=${email}`,
  resetPassword: () => `${homnicsApiUrl}/${routePrefix}/account/reset-password`,
  changePasswordInApp: (userId) =>
    `${homnicsApiUrl}/${routePrefix}/account/${userId}/password`,

  // profile
  uploadAvatar: (userId) =>
    `${homnicsApiUrl}/${routePrefix}/account/${userId}/avatar`,
  currentUserBiodata: () =>
    `${homnicsApiUrl}/${routePrefix}/account/current-user`,
  updatecurrentUser: (userId) =>
    `${homnicsApiUrl}/${routePrefix}/account/${userId}/profile`,
  getGenderList: () => `${homnicsApiUrl}/${routePrefix}/account/gender-list`,
  getRelationshipList: () =>
    `${homnicsApiUrl}/${routePrefix}/account/relationship-list`,
  getPermissionList: () =>
    `${homnicsApiUrl}/${routePrefix}/account/permission-list`,
  getAllProfessionalsDetailsFromBackend: () =>
    `${homnicsApiUrl}/${routePrefix}/professional/all?pageSize=100`,

  // Appointments
  postNewAppointment: () => `${homnicsApiUrl}/${routePrefix}/appointment`,
  getAvailableProfessionalsForAppointments: (appointmentDate) =>
    `${homnicsApiUrl}/${routePrefix}/professional/available?pageSize=100&appointmentDate=${appointmentDate}`,
  getUsersAppointmentByStatusDetails: (beneficiaryId, status) =>
    `${homnicsApiUrl}/${routePrefix}/appointment/by-status/beneficiary/${beneficiaryId}?status=${status}&pageSize=200`,
  updateAppointment: (appointmentId, professionalId, appointmentStatus) =>
    `${homnicsApiUrl}/${routePrefix}/appointment/${appointmentId}/accept-decline/${professionalId}?appointmentStatus=${appointmentStatus}`,
  generateMeetingJwt: () => `${homnicsApiUrl}/${routePrefix}/meeting/jitsi`,

  // Reviews
  postNewAppointmentReview: () =>
    `${homnicsApiUrl}/${routePrefix}/appointment/review`,
  updateAppointmentReview: () =>
    `${homnicsApiUrl}/${routePrefix}/appointment/review`,
  postProfessionalReview: () =>
    `${homnicsApiUrl}/${routePrefix}/professional/review`,
  getReviewsWithProfessionalId: (professionalId) =>
    `${homnicsApiUrl}/${routePrefix}/professional/reviews/${professionalId}`,
  getReviewsWithPlanBeneficiaryId: (planBeneficiaryId) =>
    `${homnicsApiUrl}/${routePrefix}/professional/review/${planBeneficiaryId}/by-planBeneficiary`,

  // Prescriptions
  postNewPatientPrescription: () =>
    `${homnicsApiUrl}/${routePrefix}/prescription`,
  updatePatientPrescription: () =>
    `${homnicsApiUrl}/${routePrefix}/prescription`,
  getAllMedications: () => `${homnicsApiUrl}/${routePrefix}/prescription/all`,
  getPatientPrescriptionsByBeneficiaryId: (beneficiaryId) =>
    `${homnicsApiUrl}/${routePrefix}/prescription/by-beneficiaryId/${beneficiaryId}
    `,

  // Notifications
  getNotificationsFromBackend: (userId, page) =>
    `${homnicsApiUrl}/${routePrefix}/notification/${userId}?pageNumber=${page}`,
  updateNotificationsToRead: () =>
    `${homnicsApiUrl}/${routePrefix}/notification`,

  // Authentication
  authRevoke: () => `${homnicsApiUrl}/${routePrefix}/authentication/revoke`,
  authRefresh: () => `${homnicsApiUrl}/${routePrefix}/authentication/refresh`,
  authToken: () => `${homnicsApiUrl}/${routePrefix}/authentication/token`,

  // stats
  stats: () => 'https://jsonplaceholder.typicode.com/todos/1',

  // subscriptions
  // returns health plans alone
  getAllHealthPlans: () => `${homnicsApiUrl}/${routePrefix}/plan/health-plans`,
  // returns only durations
  getPlanDurations: () => `${homnicsApiUrl}/${routePrefix}/plan/durations`,
  // returns both plans and durations
  getHealthPlanDurations: () =>
    `${homnicsApiUrl}/${routePrefix}/plan/plans-durations`,

  getActiveUserPlanWithUserId: (userId) =>
    `${homnicsApiUrl}/${routePrefix}/plan/user-plan/active/${userId}`,
  getUserPlanHistoryWithUserId: (userId) =>
    `${homnicsApiUrl}/${routePrefix}/plan/user-plan/user/${userId}`,
  getBeneficiariesWithUserPlanId: (userPlanId) =>
    `${homnicsApiUrl}/${routePrefix}/plan/user-plan/beneficiaries/${userPlanId}`,
  postNewUserPlanWithUserIdAndPaymentReference: () =>
    `${homnicsApiUrl}/${routePrefix}/plan/user-plan`,
  deleteUserPlanWithUserPlanId: (userPlanId) =>
    `${homnicsApiUrl}/${routePrefix}/plan/user-plan/${userPlanId}`,
  postNewBeneficiaryWithUserPlanId: () =>
    `${homnicsApiUrl}/${routePrefix}/plan/beneficiary`,
  updateBeneficiaryWithPlanBeneficiaryId: () =>
    `${homnicsApiUrl}/${routePrefix}/plan/beneficiary`,
  deleteBeneficiaryWithPlanBeneficiaryId: (planBeneficiaryId) =>
    `${homnicsApiUrl}/${routePrefix}/plan/beneficiary/${planBeneficiaryId}`,

  // payments
  generatePlanPaymentReferenceForPaystack: () =>
    `${homnicsApiUrl}/${routePrefix}/payment/generate-plan-payment-reference`,

  // records
  addRecordFile: () => `${homnicsApiUrl}/${routePrefix}/record/file`,
  getRecordsCategory: () =>
    `${homnicsApiUrl}/${routePrefix}/record/record-category`,
  addNewRecord: () => `${homnicsApiUrl}/${routePrefix}/record`,
  getRecordById: (recordId) =>
    `${homnicsApiUrl}/${routePrefix}/record/${recordId}`,
  getRecords: (planBeneficiaryId) =>
    `${homnicsApiUrl}/${routePrefix}/record?planBeneficiaryId=${planBeneficiaryId}`,
  deleteRecord: (recordId) =>
    `${homnicsApiUrl}/${routePrefix}/record/${recordId}`,
};

export default API;
