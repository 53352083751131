import {
  createStyles,
  TextInput as MTextInput,
  TextInputProps as MTextInputProps,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  error: {
    fontSize: theme.fontSizes.sm,
  },
}));

export interface TextInputProps extends MTextInputProps {}

export const TextInput = (props: TextInputProps) => {
  const { classes } = useStyles();

  return (
    <MTextInput
      classNames={{
        error: classes.error,
      }}
      size="lg"
      radius={'md'}
      {...props}
    />
  );
};
