import {
  createStyles,
  Navbar,
  NavbarProps,
  useMantineTheme,
  Box,
  Stack,
} from '@mantine/core';
import { Link } from 'components/Link/Link';
import { ILinkItem } from 'types/ILinkItem';
import { useLocation } from 'react-router';

const useStyles = createStyles(
  (theme, { isActive }: { isActive?: boolean }) => ({
    link: {
      color:
        theme.colorScheme === 'dark' ? theme.white : theme.colors.secondary[9],
      borderRight: isActive ? `3px solid ${theme.colors.secondary[9]}` : 'none',
      fontWeight: 500,
      display: 'flex',
      opacity: isActive ? 1 : 0.6,
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover': {
        textDecoration: 'none',
        color:
          theme.colorScheme === 'dark'
            ? theme.white
            : theme.colors.secondary[9],
        opacity: 1,
      },
    },
  }),
);

export interface SidebarProps extends Omit<NavbarProps, 'children'> {
  children: React.ReactNode;
  toggleSidebar?: boolean;
}

export const Sidebar = ({ toggleSidebar, ...otherProps }: SidebarProps) => (
  <Navbar
    hiddenBreakpoint="sm"
    hidden={toggleSidebar}
    width={{ md: 300, lg: 330 }}
    {...otherProps}
  />
);

export interface SidebarMenuProps {
  items: ILinkItem[];
  onClick: any;
}

export const SidebarMenu = ({ items, onClick }: SidebarMenuProps) => {
  const location = useLocation();
  const activeItem = items.find(
    (item) => item.href === location.pathname,
  )?.href;

  return (
    <Stack spacing={30}>
      {items?.map((item) => (
        <SidebarMenuItem
          {...item}
          key={item.href}
          isActive={item.href === activeItem}
          onClick={onClick}
        />
      ))}
    </Stack>
  );
};

export const SidebarMenuItem = ({
  href,
  label,
  Icon,
  isActive,
  onClick,
}: ILinkItem & {
  isActive?: boolean;
  onClick?: () => void;
}) => {
  const theme = useMantineTheme();
  const { classes } = useStyles({ isActive: !!isActive });

  return (
    <Box onClick={onClick}>
      <Link to={href} className={classes.link}>
        {Icon &&
          Icon({ fill: isActive ? theme.white : theme.colors.secondary[8] })}
        <Box component="span" ml={16}>
          {label}
        </Box>
      </Link>
    </Box>
  );
};
