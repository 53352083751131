import {
  Avatar,
  Group,
  Modal,
  createStyles,
  Indicator,
  Title,
} from '@mantine/core';
import { RiNotification2Line } from 'react-icons/ri';
import { ModalTitle } from 'components/ModalTitle';
import { Link } from 'react-router-dom';
import {
  EUnreadNotificationStatus,
  IUnreadNotification,
} from 'features/Notifications/types';
import { UnreadNotificationModalContainer } from 'features/Notifications/components/UnreadNotificationModalContainer';
import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleFetchNotificationsFromBackend } from 'redux/notifications';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { APP_ROUTES_LIST } from 'types';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing.md,
  },
  iconWrapper: {
    width: 40,
    height: '40px',

    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.secondary[9]
        : theme.colors.gray[0],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    cursor: 'pointer',
  },
}));

interface AppHeaderProps {
  componentName?: string;
}

export const AppHeader = ({ componentName = '' }: AppHeaderProps) => {
  const { classes } = useStyles();

  const { currentUserDetails } = useAppSelector((state) => state?.profileStore);
  const { allNotifications = [] } = useAppSelector(
    (state) => state?.notificationStore,
  );
  const [opened, { open, close }] = useDisclosure(false);
  const { idToken } = useOidcIdToken();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (currentUserDetails?.userId) {
      dispatch(
        handleFetchNotificationsFromBackend(
          currentUserDetails?.userId,
          1,
          idToken,
        ),
      );
    }
  }, [currentUserDetails?.userId]);

  return (
    <>
      <Group className={classes.root}>
        <Group>
          {componentName && (
            <>
              <MdKeyboardArrowLeft
                size={40}
                onClick={() => {
                  window.history.back();
                }}
                cursor="pointer"
              />
              <Title weight={500} order={2}>
                {componentName}
              </Title>
            </>
          )}
        </Group>

        <Group>
          <Indicator
            color="red"
            offset={4}
            className={classes.iconWrapper}
            disabled={
              allNotifications?.filter(
                (notification: IUnreadNotification) =>
                  notification.notificationStatus ===
                  EUnreadNotificationStatus.Active,
              )?.length === 0 || allNotifications?.length === 0
            }
          >
            <RiNotification2Line size={21} onClick={open} />
          </Indicator>
          <Modal
            opened={opened}
            size={'35rem'}
            withCloseButton
            onClose={close}
            overflow="inside"
            title={<ModalTitle title="Notifications" />}
          >
            <UnreadNotificationModalContainer />
          </Modal>
          <Link to={APP_ROUTES_LIST.ACCOUNT}>
            <Avatar
              radius={'xl'}
              size={40}
              src={`${currentUserDetails?.avatar}?v=${Date.now()}`}
              alt="profile image"
            />
          </Link>
        </Group>
      </Group>
    </>
  );
};
