import {
  Stack,
  Group,
  createStyles,
  GroupProps,
  StackProps,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    background: theme.colors.secondary[0],

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      background: theme.white,
      padding: theme.spacing.md,
    },
  },
  leftSide: {
    width: '45%',
    minHeight: '100vh',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: 'none',
    },
  },
  rightSide: {
    flex: 1,
    minHeight: '100vh',
    background:
      theme.colorScheme === 'dark' ? theme.colors.secondary[9] : theme.white,
    borderTopLeftRadius: theme.radius.xl,
    borderBottomLeftRadius: theme.radius.xl,
  },
}));

export interface AuthLayoutProps extends GroupProps {}

export const AuthLayout = ({ children, ...otherProps }: AuthLayoutProps) => {
  const { classes } = useStyles();

  return (
    <Group className={classes.root} spacing={0} {...otherProps}>
      {children}
    </Group>
  );
};

export const AuthLayoutLeftSide = ({ children, ...otherProps }: StackProps) => {
  const { classes } = useStyles();

  return (
    <Stack
      className={classes.leftSide}
      align="center"
      justify="center"
      {...otherProps}
    >
      {children}
    </Stack>
  );
};

export const AuthLayoutRightSide = ({
  children,
  ...otherProps
}: StackProps) => {
  const { classes } = useStyles();

  return (
    <Stack
      className={classes.rightSide}
      align="center"
      justify="center"
      {...otherProps}
    >
      {children}
    </Stack>
  );
};
