import { OidcSecure, useOidcIdToken } from '@axa-fr/react-oidc';
import { AppShell, Box, createStyles, MediaQuery } from '@mantine/core';
import { NavbarMenu } from 'components/NavbarMenu';
import { SidebarContainer } from 'components/SidebarContainer';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleGeneratePaymentPlanReference } from 'redux/subscription';
import { usePaystackHook } from 'features/Subscription/hooks/usePaystackHook';
import { BannerIncompleteAccount } from 'components/BannerIncompleteAccount';
import ChatIconContainer from 'features/AISupport/components/ChatIconContainer';

const useStyles = createStyles((theme) => ({
  body: {
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.secondary[9]
        : theme.colors.secondary[0],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
  toggle: {
    background: theme.colors.secondary[9],
  },
  smallscreen: {
    [theme.fn.smallerThan('lg')]: {
      display: 'none',
    },
  },
  fixed: {
    position: 'fixed',
    bottom: 0,
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },
  appWrapper: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    borderRadius: '10px',
    minHeight: '100vh',
  },
}));

export const AppLayout = () => {
  const { classes } = useStyles();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const handleToggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const { idToken } = useOidcIdToken();
  const dispatch = useAppDispatch();
  const { handleCreateNewHealthPlanForUser } = usePaystackHook();
  const { currentUserDetails } = useAppSelector((state) => state?.profileStore);
  const {
    isActivePlanSuccess,
    activeUserPlan,
    selectedHealthPlanForSubscription,
  } = useAppSelector((state) => state?.subscriptionStore);

  const [showIncompleteAccountBanner, setShowIncompleteAccountBanner] =
    useState(false);

  useEffect(() => {
    if (currentUserDetails.userId) {
      if (!currentUserDetails.fullName) {
        setShowIncompleteAccountBanner(true);
      } else {
        setShowIncompleteAccountBanner(false);
      }
    }
  }, [currentUserDetails?.fullName]);

  const generatePaymentPlanRefenceData = {
    healthPlanId: selectedHealthPlanForSubscription.id,
    email: currentUserDetails.email,
  };

  useEffect(() => {
    const handleSubscribeFreemium = async () => {
      if (activeUserPlan.planId) {
        return;
      }
      if (isActivePlanSuccess && !activeUserPlan?.planId) {
        await dispatch(
          handleGeneratePaymentPlanReference(
            idToken,
            generatePaymentPlanRefenceData,
          ),
        );
        await handleCreateNewHealthPlanForUser();
      }
    };

    handleSubscribeFreemium();
  }, [isActivePlanSuccess, activeUserPlan?.planId]);

  return (
    <OidcSecure>
      <AppShell
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        header={
          <MediaQuery largerThan="md" styles={{ display: 'none' }}>
            <>
              {!isSidebarVisible && (
                <NavbarMenu handleToggleSidebar={handleToggleSidebar} />
              )}
            </>
          </MediaQuery>
        }
        navbar={
          <MediaQuery
            smallerThan="md"
            styles={{ display: isSidebarVisible ? 'block' : 'none' }}
          >
            <Box>
              <SidebarContainer
                handleToggleSidebar={handleToggleSidebar}
                isSidebarVisible={isSidebarVisible}
                setIsSidebarVisible={setIsSidebarVisible}
              />
            </Box>
          </MediaQuery>
        }
        fixed
        classNames={classes}
      >
        <div className={classes.appWrapper}>
          {showIncompleteAccountBanner && (
            <BannerIncompleteAccount
              onClose={() => setShowIncompleteAccountBanner(false)}
            />
          )}
          <Outlet />
        </div>
        <ChatIconContainer />
      </AppShell>
    </OidcSecure>
  );
};
